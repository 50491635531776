import styled from 'styled-components';
import Icon from './Icon';

import {
  StepsHeader,
  FlexRow,
  FlexItem,
} from './basicComponents';
import { RoundButton } from './Buttons';
import i18n from '../i18n';

const MiddlePartHeader = styled(StepsHeader)`
  text-align: center;
  color: white;
`;

const LeftPartHeader = styled(StepsHeader)`
  text-align: left;
  color: white;
`;

const RightPartHeader = styled(StepsHeader)`
  text-align: right;
  color: white;
`;

const StyledFlexRow = styled(FlexRow)`
  background-color: #46446F;
  padding: 1rem;
    &:not(:last-child) {
    margin-bottom: 0;
  }
`;

const StyledFlexFooterRow = styled(StyledFlexRow)`
  margin-top: auto;
  background-color: inherit;
  justify-content: space-between;
  padding: 1rem;
    &:not(:last-child) {
    margin-bottom: 0;
  }
`;

const StyledFlexItem = styled(FlexItem)`
  ${({ isCursor }) => isCursor && 'cursor: pointer;'};
`;

export const HeaderFeedbackStep = ({ header, showPrev, previousStep, skipStep, nextButtonLabel }) => {
  return (
    <StyledFlexRow>
        <StyledFlexItem isCursor={false} onClick={previousStep}>
          {showPrev && (
            <LeftPartHeader>
              <Icon
                icon="arrow_back_ios"
                iconLib="material"
                iconColor={'white'}
                iconSize={14}
                withoutContainer={true}
              />
            </LeftPartHeader>
          )}
        </StyledFlexItem>
        <StyledFlexItem isCursor={false}><MiddlePartHeader>{header}</MiddlePartHeader ></StyledFlexItem>
        <StyledFlexItem isCursor={!!nextButtonLabel} onClick={() => skipStep(true)}><RightPartHeader>{nextButtonLabel}</RightPartHeader></StyledFlexItem>
    </StyledFlexRow>
  );
};

export const FooterFeedbackStep = ({ showPrev, showNext, nextStep, previousStep, nextButtonLabel, isDisabledNext }) => {

  return (
    <StyledFlexFooterRow>
      {showPrev && (
        <StyledFlexItem>
          <RoundButton
            label={i18n('back')}
            onClick={previousStep}
            primary
            color={'#46446F'}
            padding='10px 20px'
          />
        </StyledFlexItem>
      )}
      {showNext && (
        <StyledFlexItem>
          <RoundButton
            label={nextButtonLabel}
            onClick={nextStep}
            primary
            darkButton
            color={'#46446F'}
            disabled={isDisabledNext}
            padding={!showPrev ? null : '10px 20px'}
          />
        </StyledFlexItem>
      )}
    </StyledFlexFooterRow>
  );
};

import React, { useMemo, useState, useEffect } from 'react';

import ChatWizard from '../containers/ChatWizard';
import PrebookSummary from './PrebookSummary';

const PrebookConteiner = ({ prebook, ongoing, joinTheCall, closeModal, routeParams, openConfirmationModal, orderPrebooking, cloningView = false, editModeView = false, canselView = false }) => {
  const [cloning, setCloning] = useState(cloningView);
  const [editMode, setEditMode] = useState(editModeView);

  useEffect(() => {
    if (canselView) {
      openConfirmationModal('prebookPreviewModal', true);
    }
  }, [canselView, openConfirmationModal]);

  return useMemo(() => {
    if (cloning || editMode) {
      const prebookForClone = { ...prebook, invoiceRef: '' };
      return (
        <ChatWizard
          cloning={cloning}
          editMode={editMode}
          closeModal={closeModal}
          route={{
            params: {
              ...routeParams?.params,
              ...prebookForClone
            }
          }}
        />
      );
    }
    return (
      <PrebookSummary
        clonePrebook={setCloning}
        editPrebook={setEditMode}
        prebook={prebook}
        ongoing={ongoing}
        joinTheCall={joinTheCall}
        closeModal={closeModal}
        confirmationDialog={() => openConfirmationModal('prebookPreviewModal')}
      />
    );
  }, [cloning, editMode, closeModal, routeParams, prebook, orderPrebooking, openConfirmationModal]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default PrebookConteiner;

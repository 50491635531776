import styled from 'styled-components';
import { StepsHeader } from './basicComponents';
import { HeaderFeedbackStep } from './HeaderFeedbackStep';
import i18n from '../i18n';
import Spinner from '../components/Spinner';

const StyledStepsHeader = styled(StepsHeader)`
  color: ${({ isCover }) => isCover ? 'white' : '#46446F'};
  background: ${({ isCover }) => isCover ? '46446F' : 'transparent'};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  height: calc(100vh - 116px);
  background: rgb(246,249,252);
  background: linear-gradient(180deg, rgba(70,68,111,1) 8%, rgba(246,249,252,1) 8%);
`;

const TextContainer = styled.div`
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 16px;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  background-color: white;
  width: 80%;
`;

const DefaultFeedbackStep = (props) => {
  const { header, errorKey, indicator } = props;

  return (
    <>
      <HeaderFeedbackStep
        header={header}
      />
      <ContentContainer>
        {indicator ? (<Spinner overlay />): null}
        <StyledStepsHeader isCover={true}>{i18n('how_did_we_do')}</StyledStepsHeader>
        <TextContainer>
          <StyledStepsHeader>{i18n(errorKey)}</StyledStepsHeader> 
        </TextContainer>
      </ContentContainer>
    </>
  );
};

export default DefaultFeedbackStep;

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import qs from 'query-string';

import { MyTulkaInput } from '../components/Input';
import { MyTulkaButton, MyTulkaLinkButton } from '../components/Buttons';
import Spinner from '../components/Spinner';
import {
  FullBackgroundImageContainer,
  CenteredBlockContainer,
  VersionLabel,
  LogoImg,
  DialogBlock,
} from '../components/basicComponents';

import i18n, { setLangOnLoginPage } from '../i18n';
import { manifest } from '../Manifest';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import Logo from '../images/myTulkaLogo.png';
import ParadigmaLogoImage from '../images/paradigma_logo2.png';
import { isLuonaHost } from '../utils/luonaSpecificData';
import BackgroundImage from '../images/login-background.jpeg';

const StyledCenteredBlockContainer = styled(CenteredBlockContainer)`
  background-color: rgba(255, 255, 255, 0.75);
`;

const StyledMyTulkaButton = styled(MyTulkaButton)`
  margin-top: 46px;
`;

const StyledMyTulkaLinkButton = styled(MyTulkaLinkButton)`
  margin-top: 6px;
  font-weight: 600;
  letter-spacing: .8px;
`;

const InputWrapper = styled.div`
  margin-top: 10px;
`;

const appId = 'lq60m5t9';
const visitorName = 'Webapp visitor ' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

const Login = () => {
  const context = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const { login } = useContext(FunctionsContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [indicator, setIndicator] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLangOnLoginPage();
  }, []);

  useEffect(() => {
    setError(null);
    setButtonDisabled(![username, password].every(value => value.length > 0));
  }, [username, password]);

  useEffect(() => {
    const parsed = qs.parse(window.location.search);
    if (parsed.username && parsed.password) {
      processLogin(parsed.username, parsed.password);
    }
    if (window?.Intercom) {
      window?.Intercom('boot', {
        app_id: appId,
        name: visitorName
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processLogin = async (anUsername, anPassword) => {
    setIndicator(true);
    try {
      await login(
        anUsername || username,
        anPassword || password
      );
    } catch (e) {
      setIndicator(false);
      setError(i18n('loginFailed'));
    }
  };

  const onKeyPress = async e => {
    if (e.key === 'Enter') {
      await processLogin();
    }
  };

  const isParadigma = isLuonaHost();
  const logo = isParadigma ? ParadigmaLogoImage : Logo;
  const altText = isParadigma ? 'Paradigmasoft' : 'Túlka';

  return (
    <FullBackgroundImageContainer
      backgroundImage={BackgroundImage}
    >
      <StyledCenteredBlockContainer>
        <DialogBlock
          backgroundColor={'#ffffff'}
        >
          <LogoImg src={logo} title={altText}/>
          <InputWrapper>
            <MyTulkaInput
              type="text"
              id="username"
              title={i18n('username')}
              titleFontSize={layout.smallFontSize}
              placeholder={i18n('username')}
              value={username}
              onChange={setUsername}
              fontSize={layout.mediumFontSize}
              iconLib={'material'}
              icon={'account_circle'}
            />
          </InputWrapper>
          <InputWrapper>
            <MyTulkaInput
              type="password"
              id="password"
              title={i18n('password')}
              titleFontSize={layout.smallFontSize}
              placeholder={i18n('password')}
              value={password}
              onChange={setPassword}
              onKeyPress={onKeyPress}
              fontSize={layout.mediumFontSize}
              error={error ? error : ''}
              iconLib={'material'}
              icon={'lock'}
            />
          </InputWrapper>
          <StyledMyTulkaLinkButton
            fontSize={layout.smallFontSize}
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              context.history.replace('/forgotPassword');
            }}
          >
            {i18n('forgot_password_link')}
          </StyledMyTulkaLinkButton>
          <StyledMyTulkaButton
            label={i18n('loginButton')}
            disabled={buttonDisabled}
            onClick={() => processLogin()}
            data-test='loginButton'
          />
          <VersionLabel
            fontSize={layout.smallFontSize}
            color={colors.webMediumGray}
          >
            {manifest.appVersion}
          </VersionLabel>
        </DialogBlock>
        {
          indicator ? (
            <Spinner
              overlay
            />
          ) : null
        }
      </StyledCenteredBlockContainer>
      <iframe height="0" width="0" style={{'borderWidth': 0}} src="https://tulka.domo.com/auth/signout"></iframe>
      <iframe height="0" width="0" style={{'borderWidth': 0}} src="https://extranet.tulka.com/oidc/session/end"></iframe>
    </FullBackgroundImageContainer>
  );
};

export default Login;

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Redirect, Route, useHistory, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ParadigmaHead, TulkaHead, TulkaRegistrationHead } from '../components/HelmetHeads';
import amplitude from 'amplitude-js';
import { hotjar } from 'react-hotjar';
import styled, { createGlobalStyle } from 'styled-components';
import Push from 'push.js';
import PropTypes from 'prop-types';

import { showError, showWarning } from '../utils/msgbox';
import { showError as showErrorToast, showSuccess as showSuccessToast} from '../services/toastService';
import PrivateRoute from '../components/PrivateRoute';
import { manifest } from '../Manifest';
import { getCurrentEnv, ENDPOINTS, URL, WSURL, VERSION } from '../API';
import { onlyLuonaCustomerPathes, isLuonaHost, isLuonaCustomer } from '../utils/luonaSpecificData';
import sound from '../utils/audio';

import i18n from '../i18n';

import MainWorker from '../workers/main.worker';

import '../styles/app.scss';
import '../styles/ohjaa.scss';

import '../images/apple-touch-icon.png';
import '../images/ic_launcher.png';

import '../icons/favicon.ico';
import '../icons/ohjaa_favicon.ico';

import '../sounds/disconnect.wav';
import signal from '../sounds/notification.wav';

import 'react-toastify/dist/ReactToastify.css';

import DownloadChrome from './DownloadChrome';
import Home from './Home';
import Billing from './Billing';
import Settings from './Settings';
import Login from './Login';
import SignupLuona from './SignupLuona';
import PrebookingLuona from './PrebookingLuona';
import Customer from './customer';
import Feedback from './feedback';
import FeedbackV2 from './FeedbackV2';
import VideoChat from './videoChat';
import ForgotPassword from './ForgotPassword';
import Statistics from './statistics';
import FeedbackList from './feedbackList';
import Reports from './Reports';
import InviteHandle from './InviteHandle';
import InterpreteHome from './InterpreteHome';
import InterpretersCalendarView from './InterpretersCalendarView';
import MeetingRoom from './MeetingRoom';
import LuonaWizard from './LuonaWizard';
import LuonaFeedback from './LuonaFeedback';
import ErrorPage from './ErrorPage';
import SelfRegistration from './SelfRegistration';
import SelfRegistrationVerificationPage from './SelfRegistrationVerificationPage';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  initIntercom,
  intercomShutdown,
  intercomAction,
} from '../utils/intercom';
import { redirectToPilot, SELFREGISTRATION_ROUTES } from '../utils/redirectUtils';
import {
  getLanguagePairs,
  getSelectableLanguage,
} from '../utils/languageUtils';
import getColors from '../layout/colors';
import Layout from '../layout/Layout2020';
import extranetPagesConfigurator from '../utils/extranetPagesConfigurator';

import { getUser } from '../services/userService';
import { login, logout } from '../services/authService';
import { getLanguageList } from '../services/languageService';
import { getOrders } from '../services/invoiceService';
import { endChatRoom } from '../services/chatRoomService';
import { getIdentity } from '../services/authLuona';
import { analyticsSetUser, changePage, logAnalytics, analyticLoading } from '../services/analyticsLogger';

import { getUserFromLocalstorage } from '../utils/localStorage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { localStorageKeysMap } from '../utils/feedbackUtils';

const checkBrowser = (browserName) => {
  if (Array.isArray(window.navigator?.userAgentData?.brands)) {
    return window.navigator?.userAgentData?.brands.find(browser => browser.brand.toLowerCase().includes(browserName)); 
  } else { 
    return false;
  }
}; 

const env = getCurrentEnv();
if (env === 'production' || env === 'pilot') {
  const hjid = env === 'production' ? '2213394' : '2228871';
  hotjar.initialize(hjid, 6);
}

if (!navigator.userAgent.includes('StatusCake')) {
  amplitude.getInstance().init('118eab759147a3846c4efa04f459de4d');
}

const from = 'webapp.tulka.com';
const to = 'app.tulka.com';
if (window.location.host === from) {
  window.location.href = window.location.href.replace(from, to);
}

const refreshPage = () => {
  window.location.reload();
};

async function reloadPage() {
  if ('caches' in window) {
    const keys = await caches.keys();
    await Promise.all(keys.map((key) => caches.delete(key)));
  }

  window.location.reload(true);
}

const isPilotUser = (aUser) => {
  return aUser && aUser.isPilot;
};

const GlobalStyle = createGlobalStyle`
  .react-tooltip-lite {
    border-radius: 6px;
    padding: ${({ padding }) => padding * 2}px !important;
  }
`;

function hasCorrectBrowser() {
  const hasChrome = !!window.chrome;
  const hasFirefox =
    !!window.RTCPeerConnection && navigator.userAgent.includes('Firefox');
  let safariVersion = /version\/(\d*)/gi.exec(navigator.userAgent);

  if (safariVersion) {
    safariVersion = ~~safariVersion[1];
  }

  return hasChrome || hasFirefox || safariVersion >= 11;
}

const getInitialEntry = (user, isLogoutProcess) => {
  if (user && user.id && !isLogoutProcess) {
    return ['/'];
  } else if (!hasCorrectBrowser()) {
    amplitude.getInstance().logEvent('Non-compatible browser', {
      userAgent: navigator.userAgent,
    });
    return ['/chrome'];
  }
  return ['/login'];
};

const isRegistration = () => {
  return window.location.pathname === SELFREGISTRATION_ROUTES.email;
};
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
const SelfRegistrationWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <SelfRegistration />
    </GoogleReCaptchaProvider>
  );
};

const setBrandingHead = () => {
  if (isLuonaHost()) {
    return <ParadigmaHead />;
  }
  if (isRegistration()) {
    return <TulkaRegistrationHead />;
  }
  return <TulkaHead />;
};

const getUserRoleSpecificPaths = (user) => {
  const additionalPages = [];
  if (isLuonaCustomer()) {
    return [];
  }
  if (user?.role === 'admin') {
    additionalPages.push({
      path: ['/interpreters/language/:langId/interpreter/:interpreterId', '/interpreters'],
      component: InterpretersCalendarView,
    });
  }
  if (user?.type === 'Interpreter') {
    return [
      {
        path: '/',
        component: InterpreteHome,
      },
      {
        path: '/reports',
        component: Reports,
      },
      {
        path: '/settings',
        component: Settings,
      },
    ];
  }
  const pages = extranetPagesConfigurator(user);
  if (pages && pages.length > 0) {
    return [
      {
        path: '/',
        component: Reports,
      },
      {
        path: '/booking',
        component: Home,
      },
      {
        path: '/billing',
        component: Billing,
      },
      ...additionalPages,
      {
        path: '/settings',
        component: Settings,
      },
    ];
  }
  return [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/billing',
      component: Billing,
    },
    {
      path: '/reports',
      component: Reports,
    },
    ...additionalPages,
    {
      path: '/settings',
      component: Settings,
    },
  ];
};

const StyledToastContainer = styled(ToastContainer)`
  width: 366px;
  .Toastify__toast {
    min-height: 70px;
    border-radius: 6px;
    .Toastify__toast-icon {
      width: 26px;
    }
    .Toastify__close-button {
      width: 24px;
    }
  }
  .Toastify__toast--error {
    background-color: ${({ errorBackground }) => errorBackground};
    .Toastify__toast-body > * {
      color: ${({ color }) => color};
    }
  }
  .Toastify__toast--success {
    background-color: ${({ successBackground }) => successBackground};
    .Toastify__toast-body > * {
      color: ${({ color }) => color};
    }
  }
  .Toastify__close-button {
    align-self: center;
    color: ${({ color }) => color};
    opacity: 1;
  }
  .Toastify__progress-bar {
    opacity: 0 !important;
  }
`;

const Tulka = () => {
  const APP_ID = 'lq60m5t9';
  const [, setTimer] = useState(null);
  const [delay] = useState(300000); // 5min
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [user, setUser] = useState(getUserFromLocalstorage());
  const getPagesWithReloadTimer = userObj => {
    const isSelfRegistration = location.pathname.includes('rekisteroidy') || location.pathname.includes('rekisterointi-valmis');
    if (userObj && userObj?.type === 'Interpreter' || isLuonaHost() || isSelfRegistration) {
      return [];
    }
    return ['/', '/billing', '/settings'];
  };
  const pages = getPagesWithReloadTimer(user);
  const [pagesWithReloadTimer] = useState(pages);
  const [busy, setBusy] = useState(!pages.includes(window.location.pathname));
  const [colors] = useState(getColors());
  const search = useLocation().search;

  const getParamFromHash = (hash, param) => {
    if (hash) {
      const hashItems = hash.split('&');
      if (hashItems?.length) {
        return hashItems.find(item => item.includes(param))?.split('=')[1];
      }
    }
    return null;
  };

  const key = new URLSearchParams(search).get('key') || getParamFromHash(window.location.hash, 'key');
  const localeFromHash = new URLSearchParams(search).get('locale') || getParamFromHash(window.location.hash, 'locale');

  const getInvoiceCount = async () => {
    try {
      const result = await getOrders();
      setInvoiceCount(
        (result.prebookings.length || 0) + (result.chatRooms.length || 0)
      );
    } catch (e) {
      console.log('Error', e);
      return;
    }
  };

  const setReloadTimer = useCallback(() => {
    const tick = () => {
      amplitude
        .getInstance()
        .logEvent(
          busy ? 'Browser not reloaded because of busy' : 'Browser reload',
          {
            busy: busy,
            date: new Date(),
          }
        );
      if (!busy && user?.type !== 'Interpreter') {
        console.log('Browser reload', new Date());
        refreshPage();
      } else {
        console.log('Browser not reloaded because of busy', new Date());
      }
      setTimer((prevTimer) => {
        clearTimeout(prevTimer);
        return setTimeout(tick, delay);
      });
    };

    setTimer((prevTimer) => {
      clearTimeout(prevTimer);
      return setTimeout(tick, delay);
    });
  }, [busy, delay]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserDiffWay = async () => {
    if (key) {
      localStorage.removeItem('user');
      try {
        const res = await getIdentity(key);
        logAnalytics('Get identity successfully', {
          userId: res?.id
        });
        const { chatId, ...rest } = res;
        localStorage.setItem('luonaId', chatId);
        localStorage.setItem('user', JSON.stringify(rest));
        if (localeFromHash) {
          localStorage.setItem('lang', localeFromHash);
        }
        document.dispatchEvent(new CustomEvent('setUserToken', {
          detail: {
            user: res
          }
        }));
        updateUser(rest);
      } catch (e) {
        console.log('GET IDENTITY ERROR: ', e);
        logAnalytics('Error get identity', {
          error: e?.message || e
        });
        history.replace('/error');
      }
    } else {
      analyticLoading();
      fetchUserAndUpdateLocally();
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    //eslint-disable-line consistent-return
    if (!busy) {
      setReloadTimer();

      return () => {
        setTimer((prevTimer) => {
          clearTimeout(prevTimer);
          return null;
        });
      };
    }
  }, [busy, setReloadTimer]);

  const [worker, setWorker] = useState(null);
  const [isLogoutProcess, setIsLogoutProcess] = useState(false);
  const [initialEntries] = useState(getInitialEntry(user, isLogoutProcess));
  const history = useHistory();

  const defaultContext = getContext(user, history);
  const defaultThemeContext = getThemeContext();
  const defaultFunctionsContext = getFunctionsContext();

  const [context, setContext] = useState(defaultContext);

  const checkFirstInteraction = () => {
    if (user?.availableUntil > 0 && isLuonaHost()) {
      logAnalytics('Need to  display is Online Notification', {
        userId: user?.id
      });
      document.dispatchEvent(new CustomEvent('needIsOnlineNotification'));
    }
  };

  useEffect(() => {
    fetchUserDiffWay();
    sound.init();
    checkFirstInteraction();
    const chatroom = localStorage.getItem('roomId');
    if (chatroom && user) {
      endChatRoom(chatroom).catch((e) => {
        console.log('Error', e);
      });
      localStorage.removeItem('roomId');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unListen = history.listen((location) => {
      if (pagesWithReloadTimer.includes(location.pathname)) {
        setBusy(false);
      } else {
        setBusy(true);
      }
    });
    return unListen;
  }, [pagesWithReloadTimer, history]);

  const handleOnline = () => {
    logAnalytics('Internet connection exist', { userId: user?.id });
    showSuccessToast(i18n('internet_connection_exist'));
    return;
  };

  const handleOffline = () => {
    logAnalytics('Internet connection lost', { userId: user?.id });
    showErrorToast(i18n('internet_connection_lost'));
    return;
  };

  const handleFreeze = () => {
    logAnalytics('Freeze event', { userId: user?.id });
    return;
  };

  const handleResume = () => {
    logAnalytics('Resume event', { userId: user?.id });
    return;
  };

  const handleTabClose = () => {
    logAnalytics('Tab closed', { userId: user?.id });
    localStorage.removeItem(localStorageKeysMap.feedbackError);
    return;
  };

  const handleVisibilityChange = () => {
    const hidden = document.hidden || document.msHidden || document.webkitHidden;
    logAnalytics('Visibility change', {
      hidden,
      userId: user?.id
    });
    return;
  };

  const handleLoad = () => {
    setTimeout(() => {
      console.log('handleLoad');
      analyticLoading();
    }, 5000);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('beforeunload', handleTabClose);
    window.addEventListener('load', handleLoad);
    window.addEventListener('freeze', handleFreeze, {capture: true});
    window.addEventListener('resume', handleResume, {capture: true});
    document.addEventListener('visibilitychange', handleVisibilityChange);

    () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('freeze', handleFreeze);
      window.removeEventListener('resume', handleResume);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  useEffect(() => {
    if (path) {
      history.replace(path);
    }
  }, [path, history]);

  const isStartWithLuonaPath = () => {
    return onlyLuonaCustomerPathes.some(p => history.location.pathname.startsWith(p));
  };

  useEffect(() => {
    if (isLuonaHost() && user?.type === 'Interpreter' && checkBrowser('edge')) {
      showError(i18n('edge_browser_is_not_supported'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !(user && user.id) && !isLuonaHost() &&
      history.location.pathname !== '/login' &&
      history.location.pathname !== '/forgotPassword' &&
      history.location.pathname !== '/invited' &&
      isStartWithLuonaPath() &&
      !history.location.pathname.startsWith('/meetings') ||
      isLogoutProcess && isLuonaHost()
    ) {
      history.replace('/login');
    }
    if (
      !(user && user?.type) && isLuonaHost() &&
      (window.location.pathname === '/' ||
      window.location.pathname === '/prebooking_luona') ||
      isLuonaCustomer() && window.location.pathname === '/'
      ) {
      history.replace('/wizard');
    }
    if (
      user && user?.type === 'Interpreter' &&
      (window.location.pathname === '/wizard' ||
      window.location.pathname === '/prebooking_luona')
      ) {
      history.replace('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, history, isLogoutProcess ]);

  useEffect(() => {
    if (user && user.id && user?.type === 'Customer') {
      getInvoiceCount();
    }
  }, [user]);

  useEffect(() => {
    const func = async (ev) => {
      if (ev.detail) {
        setInvoiceCount(ev.detail.count);
      }
    };
    document.addEventListener('updateInvoiceCount', func);
    return () => {
      document.removeEventListener('updateInvoiceCount', func);
    };
  }, []);

  useEffect(() => {
    const getPathsWhithoutIntercom = () => {
      const paths = [
        '/feedback',
        '/error'
      ];
      return paths.some(pagePath =>
        window.location.pathname.includes(pagePath)
      );
    };
    const isPageWhithoutIntercom = getPathsWhithoutIntercom();
    window.onfocus = () => {
      refresh();
    };

    window.intercomSettings = {
      app_id: APP_ID,
    };
    if (!window?.Intercom) {
      if (isPageWhithoutIntercom) {
        return;
      }
      initIntercom(APP_ID);

      const bootIntercom = () => {
        intercomAction(APP_ID, 'boot', user, location);
      };

      if (window.attachEvent) {
        window.attachEvent('onload', bootIntercom);
      } else {
        window.addEventListener('load', bootIntercom, false);
      }
    }
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user !== null && user?.type === 'Interpreter') {
      enableNotifications();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setWorkerState();

    const unregisterListener = history.listen((location) => {
      changePage(location.pathname);
      logAnalytics('Change page', {
        pathname: location.pathname,
        ...(user?.id ? {
          userId: user?.id
        } : {})
      });
      if (
        prevLocation === location.pathname ||
        location.pathname === '/login'
      ) {
        return;
      }

      if (location.pathname === '/interpreter') {
        refresh();
      }
      setWorkerState();
      setPrevLocation(location.pathname);
    });

    amplitude.getInstance().logEvent('App started');

    return function cleanup() {
      if (worker) {
        worker.onmessage = null;
        worker.postMessage({
          message: 'terminate',
        });
        setWorker(null);
      }
      unregisterListener();
    };
  }, [user, history, worker]); // eslint-disable-line react-hooks/exhaustive-deps

  const [prevLocation, setPrevLocation] = useState(initialEntries[0]);
  const [lastReload] = useState(Date.now());
  const fetchingLanguageList = useRef(false);

  // eslint-disable-next-line consistent-return
  async function processLogin(username, password, userData) {
    //eslint-disable-line consistent-return
    intercomShutdown();
    localStorage.removeItem('luonaId');

    const aUser = userData || await login(username, password);

    setUser(aUser);
    setContext(getContext(aUser));

    setWorkerState(aUser?.type === 'Interpreter');

    if ((env === 'production' || env === 'development') && isPilotUser(aUser)) {
      return redirectToPilot(
        `/#!/login?username=${encodeURIComponent(
          `${username}`
        )}&password=${encodeURIComponent(`${password}`)}`
      );
    } else if (env === 'pilot' && !isPilotUser(aUser)) {
      return redirectToPilot(
        `/#!/login?username=${encodeURIComponent(
          `${username}`
        )}&password=${encodeURIComponent(`${password}`)}`,
        ENDPOINTS.production.app
      );
    }
    logAnalytics('Login', { userId: aUser?.id });
    history.replace('/');
    setIsLogoutProcess(false);

    intercomAction(APP_ID, 'update', user, location);
    refreshPage();
  }

  function restartApp() {
    if (
      history.location.pathname === '/interpreter' ||
      history.location.pathname === '/'
    ) {
      reloadPage();
    }
  }

  async function reloadPageIfExpired() {
    if (Date.now() - lastReload > 24 * 60 * 60 * 1000) {
      reloadPage();
    }
  }

  function onBack() {
    if (history.location.pathname === '/statistics') {
      history.replace('/interpreter');
    } else if (history.location.pathname === '/feedbacks') {
      history.replace('/statistics');
    }
  }

  function enableNotifications() {
    if (user === null || user?.type !== 'Interpreter') {
      return;
    }

    Push.Permission.request(
      () => {
        console.log('Got permission for notifications.');
        amplitude.getInstance().logEvent('Notifications enabled', {
          enabled: 'true',
        });
      },
      () => {
        console.error('Failed to get permission for notifications.');
        amplitude.getInstance().logEvent('Notifications enabled', {
          enabled: 'false',
        });
      }
    );
  }

  function updateUser(anUser) {
    analyticsSetUser(anUser);
    anUser.timestamp = Date.now();

    setUser(anUser);
    setContext(getContext(anUser));
    localStorage.setItem('user', JSON.stringify(anUser));
  }

  async function fetchUserAndUpdateLocally() {
    try {
      if (user?.token) {
        const u = await getUser();
        const {
          username,
          token,
          isOnDemandLanguages,
          isPrebookingLanguages,
          isOnSiteLanguages,
          availableUntil
        } = getUserFromLocalstorage();
        if (username !== u.username && !isLuonaHost()) {
          const message = i18n('new_username_set') + u.username;
          showSuccessToast(message);
        }
        const updatedUser = Object.assign({}, u, {
          token,
          isOnDemandLanguages,
          isPrebookingLanguages,
          isOnSiteLanguages,
          availableUntil
        });
        updateUser(updatedUser);
        if (
          updatedUser &&
          updatedUser.id &&
          history.location.pathname !== ',' &&
          history.location.pathname !== '/forgotPassword'
        ) {
          if (
            (env === 'production' || env === 'development') &&
            isPilotUser(updatedUser)
          ) {
            redirectToPilot();
          } else if (env === 'pilot' && !isPilotUser(updatedUser)) {
            redirectToPilot(null, ENDPOINTS.production.app);
          }
        }
      }
    } catch (e) {
      console.log('Failed to get user.', e);
    }
  }

  async function processLogout(skipBackend) {
    const pathToRedirect = isLuonaCustomer() ? '/wizard' : '/login';
    intercomShutdown();
    localStorage.removeItem('luonaId');
    try {
      if (user) {
        if (!skipBackend) {
          try {
            const roomId = localStorage.getItem('roomId');
            if (user && roomId) {
              await endChatRoom(roomId);
            }
          } catch (e) {
            console.error('Cannot end chat room' + e);
          } // eslint-disable-line no-empty
          await logout(user);
          localStorage.clear();
          setIsLogoutProcess(true);
        }
      }

      if (worker) {
        worker.onmessage = null;
        worker.postMessage({
          message: 'terminate',
        });
        setWorker(null);
      }

      if (window?.Intercom) {
        window?.Intercom('shutdown');
      }
      setUser(null);
      logAnalytics('logout', { userId: user?.id });
      localStorage.removeItem('user');
      setPrevLocation(pathToRedirect);
      history.replace(pathToRedirect);
      if (!isLuonaHost()) {
        intercomAction(APP_ID, 'update', user, location);
      }
    } catch (e) {
      if (!skipBackend) {
        processLogout(true);
      } else {
        showError(i18n('failedLogOut'));
      }
    }
  }

  async function sessionExpired() {
    if (user) {
      logAnalytics('Session expired');
      amplitude.getInstance().logEvent('Session expired');
      await showWarning(i18n('sessionExpired'));
      await processLogout();
    }
  }

  function refresh() {
    if (worker !== null && user !== null && user?.type === 'Interpreter') {
      worker.postMessage({
        message: 'fetchNewChats',
      });
    }
  }

  function showStatistics() {
    history.replace('/statistics');
  }

  function showFeedbackList() {
    history.replace('/feedbacks');
  }

  async function fetchLanguageList(force) {
    if (fetchingLanguageList.current || !user || user?.type === 'Interpreter') {
      console.log('Already fetching language list.');
      throw new Error('Already fetching');
    }

    if (
      !force &&
      user.languagesTimestamp &&
      Date.now() - user.languagesTimestamp < 60 * 1000 &&
      user.selectableLanguages &&
      user.selectableLanguages.length > 0
    ) {
      console.log('Language list up to date.');
      throw new Error('Already fetching');
    }

    fetchingLanguageList.current = true;

    try {
      const json = await getLanguageList(user);

      user.invoicing = json.invoicing;
      user.languagePairs = getLanguagePairs(json.languagePairs);
      user.selectableLanguages = getSelectableLanguage(
        json.selectableLanguages,
        user.selectedLanguageId
      );
      user.targetLanguages = json.targetLanguages || [];
      user.languagesTimestamp = Date.now();
      user.selectableSkills = json.skills;
      user.invoicing = json.invoicing;
      user.isOnDemandLanguages = json.selectableLanguages.some(
        (l) => l.onDemand
      );
      user.isPrebookingLanguages = json.selectableLanguages.some(
        (l) => l.prebooking
      );
      user.isOnSiteLanguages = json.selectableLanguages.some((l) => l.onSite);

      if (!user.selectedTargetLanguageId) {
        user.selectedTargetLanguageId =
          json.targetLanguages && json.targetLanguages.length > 0
            ? json.targetLanguages[0].id
            : null;
      }
      await updateUser(user, false, false);

      fetchingLanguageList.current = false;
      document.dispatchEvent(new CustomEvent('getUserLangs', {
        detail: {
          user: user
        }
      }));

      amplitude.getInstance().logEvent('Language list fetched', {
        'selectable language ids': `${user.selectableLanguages
          .map((l) => l.id)
          .join(', ')}`,
        'selectable language names': `${user.selectableLanguages
          .map((l) => l.name)
          .join(', ')}`,
        'selectable language native names': `${user.selectableLanguages
          .map((l) => l.nativeName)
          .join(', ')}`,
        'selectable language tags': `${user.selectableLanguages
          .map((l) => l.languageTag)
          .join(', ')}`,
        'target language ids': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.id).join(', ')
            : ''
        }`,
        'target language names': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.name).join(', ')
            : ''
        }`,
        'target language native names': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.nativeName).join(', ')
            : ''
        }`,
        'target language tags': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.languageTag).join(', ')
            : ''
        }`,
      });
      logAnalytics('Language list fetched', {
        'selectable language ids': `${user.selectableLanguages
          .map((l) => l.id)
          .join(', ')}`,
        'selectable language names': `${user.selectableLanguages
          .map((l) => l.name)
          .join(', ')}`,
        'selectable language native names': `${user.selectableLanguages
          .map((l) => l.nativeName)
          .join(', ')}`,
        'selectable language tags': `${user.selectableLanguages
          .map((l) => l.languageTag)
          .join(', ')}`,
        'target language ids': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.id).join(', ')
            : ''
        }`,
        'target language names': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.name).join(', ')
            : ''
        }`,
        'target language native names': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.nativeName).join(', ')
            : ''
        }`,
        'target language tags': `${
          user.targetLanguages
            ? user.targetLanguages.map((l) => l.languageTag).join(', ')
            : ''
        }`,
        userId: user?.id
      });

      return json;
    } catch (e) {
      console.log('Failed to fetch language list.', e);
      fetchingLanguageList.current = false;

      if (
        e.message !== 'Update required' &&
        e.response &&
        e.response.status === 401
      ) {
        processLogout();
      }

      if (e.message === 'Session expired') {
        context.sessionExpired();
      }

      throw e;
    }
  }

  useEffect(() => {
    const fetchLanguageListData = async () => {
      try {
        await fetchLanguageList();
      } catch (e) {
        if (e.message === 'Already fetching') {
          return;
        }
      }
    };
    if (user?.token) {
      setTimeout(()=> fetchLanguageListData(), 0);
    }
  }, [user?.token]); // eslint-disable-line react-hooks/exhaustive-deps

  function getThemeContext() {
    return {
      colors: colors,
      layout: Layout,
    };
  }

  function getFunctionsContext() {
    return {
      sessionExpired: sessionExpired,
      logout: processLogout,
      refresh: refresh,
      login: processLogin,
      showStatistics: showStatistics,
      showFeedbackList: showFeedbackList,
      updateUser: updateUser,
      postToWorker: (message, params) => {
        if (worker) {
          worker.postMessage(
            Object.assign(
              {
                message,
              },
              params || {}
            )
          );
        }
      },
      reloadPageIfExpired: reloadPageIfExpired,
      restartApp: restartApp,
      onBack: onBack,
      // history: aHistory,
      fetchUserAndUpdateLocally: fetchUserAndUpdateLocally,
      setBusy: (status) => setBusy(status),
      // Not implemented
      showNotification: () => {},
      fetchLanguageList,
      setNavigating: () => {},
    };
  }

  function getContext(aUser, historyObj) {
    const userObj = aUser || user;
    const aHistory = historyObj || null;

    return {
      user: userObj || {},
      history: aHistory,
      invoiceCount,
      voiceCallSupported: true,
      notificationVisible: false,
    };
  }

  function setupWorker() {
    if ('Worker' in window && worker === null) {
      const newWorker = new MainWorker();
      setWorker(newWorker);

      newWorker.onmessage = async (e) => {
        switch (e.data.message) {
          case 'newChats':
            document.dispatchEvent(new CustomEvent('newChats', {
              detail: {
                data: e.data
              }
            }));
            if (e.data.hasNewChats) {
              logAnalytics('Interpretation request', {
                source: 'WebSocket',
                'chat room id': e.data.chatId,
                userId: user?.id
              });
            }
            // if (
            //   !e.data.userInitiated &&
            //   (user.notificationsDisabledUntil || 0) - Date.now() > 0
            // ) {
            //   break;
            // }

            // if (e.data.source === 'websocket') {
            //   if (e.data.hasNewChats) {
            //     document.dispatchEvent(new CustomEvent('newChats', {
            //       detail: {
            //         data: e.data
            //       }
            //     }));
            //     logAnalytics('Interpretation request', {
            //       source: 'WebSocket',
            //       'chat room id': e.data.chatId,
            //       userId: user?.id
            //     });
            //     amplitude.getInstance().logEvent('Interpretation request', {
            //       source: 'WebSocket',
            //       'chat room id': e.data.chatId,
            //     });
            //   } else {
            //     amplitude
            //       .getInstance()
            //       .logEvent('No more intepretation requests via websocket');
            //   }
            // }

            if (history.location.pathname === '/interpreter') {
              history.replace('/interpreter', {
                timestamp: Date.now(),
                hasNewChats: e.data.hasNewChats,
                count: e.data.count,
              });

              reloadPageIfExpired();
            } else if (history.location.pathname === '/call') {
              amplitude
                .getInstance()
                .logEvent(
                  'Interpretation request not displayed due to ongoing interpretation'
                );
            } else if (history.location.pathname === '/feedback') {
              amplitude
                .getInstance()
                .logEvent(
                  'Interpretation request not displayed due to open feedback'
                );
            }
            break;
          case 'logout':
            amplitude.getInstance().logEvent('Websocket logout');
            logAnalytics('Websocket logout', { userId: user?.id });
            sessionExpired();
            break;
          case 'reload':
            amplitude.getInstance().logEvent('Restart app');
            logAnalytics('Websocket reload', { userId: user?.id });
            reloadPage();
            break;
          case 'console.log':
            console.log(e.data.content || e.data.text);
            break;
          case 'console.error':
            console.error(e.data.content);
            break;
          case 'pollResponse': {
            if (history.location.pathname === '/call') {
              history.replace('/call', {
                event: e.data,
              });
            }
            break;
          }
          case 'analytics': {
            let params = {
              userId: user?.id
            };
            if (e.data.paramName) {
              params[e.data.paramName] = e.data.paramValue;
            }
            logAnalytics(e.data.event, params);
            amplitude.getInstance().logEvent(e.data.event, params);
            break;
          }
          case 'statistics': {
            localStorage.setItem('statistics', JSON.stringify(e.data.stats));
            break;
          }
          case 'interpreterJoined': {
            const joinedChatRoomId = e.data.chatRoomId || '';

            const analyticsParams = {
              'chat room id': joinedChatRoomId,
              'phone number': e.data.phoneNumber,
              'name length': String(e.data.name ? e.data.name.length : null),
            };

            amplitude
              .getInstance()
              .logEvent('Websocket: Interpreter joined', analyticsParams);
            break;
          }
          case 'interpreterStatus': {
            user.availableUntil = e.data.endedAt || 0;
            await updateUser(user);
            break;
          }
          default:
            console.log('Default case');
        }
      };

      newWorker.onerror = (e) => {
        console.error(e);
        logAnalytics('Websocket error', { userId: user?.id });
      };
      newWorker.onclose = () => {
        logAnalytics('Websocket closed', { userId: user?.id });
      };
      newWorker.postMessage({
        message: 'start',
      });
    }
  }

  function setWorkerState(poll) {
    if (!user) {
      return;
    }

    setupWorker();
    if (worker !== null && user !== null && user?.token) {
      worker.postMessage({
        message: 'setState',
        poll: poll || history.location.pathname === '/interpreter',
        host: URL + VERSION,
        wsHost: WSURL + VERSION,
        token: user?.token,
        locale: (
          ('languages' in navigator ? navigator.languages[0] : null) ||
          navigator.language
        ).substring(0, 2),
        appVersion: manifest.appVersion,
      });
    }
  }

  const isAuthorize = useMemo(
    () => context.user && context.user?.token,
    [context.user]
  );

  const memoThemeContext = useMemo(
    () => defaultThemeContext,
    [defaultThemeContext]
  );

  const memoFunctionsContext = useMemo(
    () => defaultFunctionsContext,
    [defaultFunctionsContext]
  );

  const memoContext = useMemo(() => defaultContext, [defaultContext]);

  const userTypeSpecificRoutes = useMemo(
    () => getUserRoleSpecificPaths(context.user),
    [context.user]
  );

  return (
    <ThemeContext.Provider value={memoThemeContext}>
      <FunctionsContext.Provider value={memoFunctionsContext}>
        <AppContext.Provider value={memoContext}>
          <GlobalStyle padding={Layout.padding} />

          <StyledToastContainer
            autoClose={3000}
            color={'white'}
            errorBackground={colors.error}
            successBackground={colors.webSuccessColor}
          />
          {setBrandingHead()}
          <Switch>
            <Route path="/chrome" component={DownloadChrome} />
            <Route path="/login" component={Login} />
            <Route path="/signup_luona" component={SignupLuona} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/invited" component={InviteHandle} />
            <Route path="/meetings/:meetingId" component={MeetingRoom} />
            {user?.type === 'Interpreter' ? (
              <PrivateRoute path="/feedback" component={Feedback} />
            ) : (
              <Route path="/feedback" component={FeedbackV2} />
            )}
            {!isLuonaHost() && (<Route path={SELFREGISTRATION_ROUTES.email} component={SelfRegistrationWithRecaptcha} />)}
            {!isLuonaHost() && (<Route path={SELFREGISTRATION_ROUTES.verification} component={SelfRegistrationVerificationPage} />)}
            {isLuonaCustomer() && (<Route path="/wizard" component={LuonaWizard} />)}
            {isLuonaHost() && (<PrivateRoute isAuthorized={isAuthorize} path="/feedback_luona" component={LuonaFeedback} />)}
            <Route path="/error" component={ErrorPage} />
            {isLuonaCustomer() && ( <Route exact path="/prebooking_luona" component={PrebookingLuona} />)}
            <PrivateRoute isAuthorized={isAuthorize} path="/customer" component={Customer} />
            <PrivateRoute isAuthorized={isAuthorize} path="/call" render={props => < VideoChat {...props}/>} />
            <PrivateRoute isAuthorized={isAuthorize} path="/statistics" component={Statistics} />
            <PrivateRoute isAuthorized={isAuthorize} path="/feedbacks" component={FeedbackList} />

            {
              userTypeSpecificRoutes.map((route) => (
                <PrivateRoute key={route.path} isAuthorized={isAuthorize} exact path={route.path} component={route.component} />
              ))
            }

            <Route path="*">
            {isLuonaCustomer() ? (<Redirect to="/wizard" />) : (<Redirect to="/" />)}
            </Route>
          </Switch>

          <audio
            id='notificationSound'
            src={signal}
            volume='1.0'
            preload='auto'
            loop
          />
          <audio
            id='disconnectSound'
            src='disconnect.wav'
            volume='1.0'
            preload='auto'
          />
          <button id='buttonForFirstInteract' hidden='hidden'></button>
        </AppContext.Provider>
      </FunctionsContext.Provider>
    </ThemeContext.Provider>
  );
};

Tulka.propTypes = {
  router: PropTypes.object,
};

export default Tulka;

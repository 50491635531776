import { useMemo } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import {
  ContainerStepBox,
  ContentStepBox,
  MainStepsHeader,
  StepsHeader
} from './basicComponents';

import { customerServicePhone, customerServicePhoneLink } from '../utils/customerService';

const StyledStepsHeader = styled(StepsHeader)`
  & + & {
    margin-top: 8px;
  }
  a {
    color: #46446F;
    cursor: pointer;
  }
`;

const NotUnicEmailInfoStep = () => {

  const message = useMemo(() => {
    return window?.Intercom ? 
      i18n('contact_service_with_chat') : 
      i18n('contact_service_without_chat');
  }, []);

  return (
    <ContainerStepBox>
      <ContentStepBox>
        <MainStepsHeader>{i18n('account_found')}</MainStepsHeader>
        <StyledStepsHeader>{i18n('email_already_registered')}</StyledStepsHeader>
        <StyledStepsHeader>
          {message}
          <a href={customerServicePhoneLink}>{customerServicePhone}</a>
        </StyledStepsHeader>
      </ContentStepBox>
    </ContainerStepBox>
  );
};

export default NotUnicEmailInfoStep;

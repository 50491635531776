const visitorName = 'there ' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

export const initIntercom = (appId) => {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function() {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + appId;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

export const intercomShutdown = () => {
  if (window?.Intercom) {
    window?.Intercom('shutdown');
  }
};

export const intercomAction = (appId, action, aUser, location) => {
  if (
    aUser?.intercomHmacWeb &&
    location.pathname !== '/login' &&
    location.pathname !== '/forgotPassword' &&
    location.pathname !== '/chrome'
  ) {
    window?.Intercom(action, {
      app_id: appId,
      name: aUser.name,
      phone: aUser.phoneNumber,
      user_id: aUser.id,
      user_hash: aUser.intercomHmacWeb
    });
  } else {
    window?.Intercom(action, {
      app_id: appId,
      name: visitorName
    });
  }
};

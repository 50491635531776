import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import styled from 'styled-components';
import StepWizard from 'react-step-wizard';
import amplitude from 'amplitude-js';
import i18n from '../i18n';
import moment from 'moment';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import { getMinSelectableDate, getStartTime, isFirstTimeBeforeSecond } from '../utils/timeUtils';
import { chatWizardStepNamesMap as stepsNames, buildSteps } from '../utils/stepBuilder';
import {
  parseNoticeMessage,
  parseNoticeCustomerData,
  isPhoneNumberValid,
  getFormatedCustomFieldsForSave,
  isRequiredCustomFieldsFilled,
  getFixedPagePath,
  isValidDateInCustomFields
} from '../utils/prebooksHelpers';
import {
  formatSelactableLanguages,
  getSelectableLangs,
  getLanguagePairs,
  getTargetLanguages,
  getSelectableLanguage,
} from '../utils/languageUtils';

import {
  createPrebooking,
  editPrebooking
} from '../services/prebookService';
import {
  getChatRoomStatus,
  createChatRoom,
} from '../services/chatRoomService';

import LogoHeader from '../components/LogoHeader';
import ButtonPanel from '../components/ButtonPanel';
import { RoundButton } from '../components/Buttons';
import PrebookingDatePage from '../components/PrebookingDatePage';
import PrebookingTimePage from '../components/PrebookingTimePage';
import PrebookingDurationPage from '../components/PrebookingDurationPage';
import LanguageListPage from '../components/LanguageListPage';
import CustomerReference from '../components/CustomerReference';
import InvoiceTargeting from '../components/InvoiceTargeting';
import CallTypePage from '../components/CallTypePage';
import PrebookingSummaryPage from '../components/PrebookingSummaryPage';
import LocationPage from '../components/LocationPage';
import RemoteAsBackup from '../components/RemoteAsBackup';
import PresenceForBackup from '../components/PresenceForBackup';
import PresencePage from '../components/PresencePage';
import PreferredInterpreterPage from '../components/PreferredInterpreterPage';
import AdditionalPrebookInfo from '../components/AdditionalPrebookInfo';
import SkillPage from '../components/SkillPage';
import Spinner from '../components/Spinner';
import CcEmails from '../components/CcEmails';
import PatientName from '../components/PatientName';

import CustomerInfo from '../components/CustomerInfo';
import ContentData from '../components/ContentData';
import { getPreferredInterpreters } from '../services/interpretersService';
import { showError } from '../services/toastService';
import { logAnalytics } from '../services/analyticsLogger';

const StyledChatWizardHeader = styled.div`
  text-align: center;
  line-height: 30px;
  font-size: 26px;
  color: ${({ color }) => (color)};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledStepWizard = styled(StepWizard)`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  & > div {
    flex: 1;
    display: flex;
    overflow: hidden;
    & > div {
      flex: 1;
      display: flex;
      overflow: hidden;
    }
  }
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const CenteredText = styled.div`
  text-align: center;
  text-align: center;
  line-height: 30px;
  font-size: 26px;
  color: ${({ color }) => (color)};
`;

const getUserDefaultUserGroup = (cloning, user, userGroup, routeParams) => {
  if (cloning) {
    return null;
  }
  if (userGroup?.invoiceRefUserGroupMandatory) {
    if (routeParams?.invoiceUserGroup) {
      return routeParams.invoiceUserGroup;
    }
    if (userGroup?.includeRootUserGroup) {
      return user.invoiceRefUserGroups.find((group) => (group.id === user.userGroup.id));
    }
  }
  return null;
};

const ChatWizard = ({ cloning, editMode, route, closeModal, ...props }) => {
  const context = useContext(AppContext);
  const themeContext = useContext(ThemeContext);
  const {
    fetchLanguageList,
    setNavigating,
    updateUser,
    sessionExpired,
  } = useContext(FunctionsContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [indicator, setIndicator] = useState(false);

  const getDefaultPresence = () => {
    if ((route.params.pages.includes(stepsNames.customerInfo) && route.params.pages.includes(stepsNames.content))) {
      return 'notice';
    }
    if (route.params.presence === 'external' && route.params.callType === 'call') {
      return 'external';
    }
    return route.params.presence || 'remote';
  };

  const parsedNoticeMessage = parseNoticeMessage(route.params.messageToInterpreter);
  const parsedNoticeCustomerData = parseNoticeCustomerData(route.params.invoiceRef);

  const getDateForCustomerBirthday = (date) => {
    if (!/[0-9]/.test(date)) {
      return '';
    }
    return new Date(date);
  };

  const [presence, setPresence] = useState(getDefaultPresence());
  const [pagesArray, setPagesArray] = useState(route.params.pages || []);

  useEffect(() => {
    if (presence === 'notice') {
      setPagesArray([stepsNames.presence, stepsNames.language, stepsNames.customerInfo, stepsNames.content, stepsNames.invoicing, stepsNames.date, stepsNames.summary, stepsNames.ccEmails, stepsNames.patientName]);
    } else {
      setPagesArray([stepsNames.dateTime, stepsNames.language, stepsNames.skill, stepsNames.invoicing, stepsNames.callType, stepsNames.summary, stepsNames.ccEmails, stepsNames.patientName]);
    }
  }, [presence]);

  const EMAILS_AMOUNT = 5;

  const getCcEmails = (ccEmails, amount) => {
    if (ccEmails) {
      const emails = ccEmails.split(',').map(email => email.trim());
      const leng = Array.isArray(emails) ? amount - emails.length : amount;
      return [...emails, ...Array(leng).fill('') ];
    }
    return Array(amount).fill('');
  };

  const [backupData, setBackupData] = useState({
    date: route.params.interpretationDate ? new Date(route.params.interpretationDate) : null,
    time: route.params.interpretationDate ? new Date(route.params.interpretationDate) : null,
    duration: route.params.durationEstimated / 60 || 0,
    selectedLanguageId: route.params.interpretedLanguage?.id || route.params.selectedLanguageId || context.user.selectedLanguageId,
    selectedTargetLanguageId: context.user.selectedTargetLanguageId,
    dialectSkill: route.params.dialect || null,
    genderSkill: route.params.gender || null,
    otherSkill: route.params.skill || null,
    callType: route.params.callType || context.user.callType,
    invoiceUserGroup: getUserDefaultUserGroup(cloning, context.user, context.user?.userGroup, route.params),
    invoiceRef: presence !== 'notice' ? route.params.invoiceRef : '',
    invoiceRecipientRef: route.params.invoiceRecipientRef || '',
    comment: presence !== 'notice' ? route.params.comments : '',
    preferredInterpreter: {},
    presence: getDefaultPresence(),
    locationCity: route.params.locationCity || '',
    streetAddress: route.params.streetAddress || '',
    subject: route.params?.subject || '',
    postcode: route.params.postcode ||'',
    messageToInterpreter: presence !== 'notice' ? route.params.messageToInterpreter : '',
    wishedInterpreterName: route.params.wishedInterpreterName || '',
    customerData: presence === 'notice' ? parsedNoticeCustomerData.customerData : '',
    customerDataBirthday: presence === 'notice' && parsedNoticeCustomerData.customerBirthday ? getDateForCustomerBirthday(parsedNoticeCustomerData.customerBirthday) : '',
    alternativeContactPerson: presence === 'notice' ? parsedNoticeMessage.alternativeContactPerson : '',
    mainPhoneNumber: presence === 'notice' && parsedNoticeMessage.phoneNumbers ? parsedNoticeMessage.phoneNumbers[0] : '',
    phoneNumbers: (presence === 'notice' && parsedNoticeMessage.phoneNumbers) ? parsedNoticeMessage.phoneNumbers?.slice(1)?.join(' ') : '',
    messageToCustomerService: presence === 'notice' ? (route.params.comments || '') : '',
    contentData: presence === 'notice' ? (parsedNoticeMessage.contentData || '') : '',
    ccEmails: getCcEmails(route.params?.ccEmails, EMAILS_AMOUNT),
    patientName: route.params?.patientName || ''
  });
  const [needToGoToSummary, setNeedToGoToSummary] = useState(false);

  const [selectableLanguages, setSelectableLanguages] = useState(context.user.selectableLanguages || []);
  const [targetLanguages, setTargetLanguages] = useState(context.user.targetLanguages || []);
  const [selectableSkills, setSelectableSkills] = useState(context.user.selectableSkills || {});
  const [languagePairs, setLanguagePairs] = useState(context.user.languagePairs || new Map());

  const [ongoingCall] = useState(false); //setOngoingCall
  const [date, setDate] = useState(route.params.interpretationDate ? new Date(route.params.interpretationDate) : null);
  const [time, setTime] = useState(route.params.interpretationDate ? new Date(route.params.interpretationDate) : null);
  const [duration, setDuration] = useState(route.params.durationEstimated / 60 || 0);

  const [originalSelectedLanguageId, setOriginalSelectedLanguageId] = useState(context.user.selectedLanguageId);
  const [selectedLanguageId, setSelectedLanguageId] = useState(route.params.interpretedLanguage?.id || route.params.selectedLanguageId || context.user.selectedLanguageId);
  const [selectedTargetLanguageId, setSelectedTargetLanguageId] = useState(context.user.selectedTargetLanguageId);

  const [dialectSkill, setDialectSkill] = useState(route.params.dialect || null);
  const [genderSkill, setGenderSkill] = useState(route.params.gender || null);
  const [otherSkill, setOtherSkill] = useState(route.params.skill || null);

  const [callType, setCallType] = useState(route.params.callType || context.user.callType);
  const [invoiceUserGroup, setInvoiceUserGroup] = useState(getUserDefaultUserGroup(cloning, context.user, context.user?.userGroup, route.params));
  const [invoiceRef, setInvoiceRef] = useState(presence !== 'notice' ? route.params.invoiceRef : '');
  const [invoiceRecipientRef, setInvoiceRecipientRef] = useState(route.params.invoiceRecipientRef || '');
  const [editAlternativeCustomerRef, setEditAlternativeCustomerRef] = useState('');
  const [editableField, setEditableField] = useState('');
  const [comment, setComment] = useState(presence !== 'notice' ? route.params.comments : '');
  const [anyPreference, setAnyPreference] = useState(true);
  const [preferredInterpreter, setPreferredInterpreter] = useState({});
  const [selectablePreferredInterpreters, setSelectablePreferredInterpreters] = useState([]);
  const [failedToFetchPreferredInterpreters, setFailedToFetchPreferredInterpreters] = useState(false);
  const [locationCity, setLocationCity] = useState(route.params.locationCity || '');
  const [streetAddress, setStreetAddress] = useState(route.params.streetAddress || '');
  const [postcode, setPostcode] = useState(route.params.postcode || '');
  const [messageToInterpreter, setMessageToInterpreter] = useState(presence !== 'notice' ? route.params.messageToInterpreter : '');
  const [presenceBackup, setPresenceBackup] = useState('');
  const [customerServiceComments , setCustomerServiceComments] = useState(''); 

  const [customFieldsValues, setCustomFieldsValues] = useState({});
  const [warningMessages, setWarningMessages] = useState([]);

  const updateSomeFieldsInBackupData = (obj) => {
    setBackupData({
      ...backupData,
      ...obj,
    });
  };

  // eslint-disable-next-line consistent-return
  const getInterpretersList = async (lang) => {
    try {
      const response = await getPreferredInterpreters();
      const filteredInterpretersBySelectedLang = response?.filter(i=> {
        const comparedLang = Object.keys(i.languages).filter(el => el.includes(lang));
        return i.languages[comparedLang];
      });
      setSelectablePreferredInterpreters(filteredInterpretersBySelectedLang);
    } catch (e) {
      setFailedToFetchPreferredInterpreters(true);
      if (e.response !== undefined && e.response.status === 400) {
        showError(i18n('error500'));
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        logAnalytics('Error get preferred interpreters', {
          error: e.message || e,
          userId: context.user?.id
        });
        showError(i18n('error500'));
      }
      return [];
    }
  };

  useEffect(() => {
    let updated = false;
    if (selectedLanguageId && !updated) { 
      getInterpretersList(selectedLanguageId);
    }
    return () => {
      updated = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguageId]);

  useEffect(() => {
    let messages = [];
    if (!moment(date).isSame(route.params.interpretationDate) || !moment(time).isSame(route.params.interpretationDate)) {
      setShowWarningMessage(false);
    }
    if (editMode) {
      setShowWarningMessage(true);
      messages.push([i18n('we_will_cancel_this_booking_and_make_a_new_one')]);
      if (moment(route.params.interpretationDate).diff(moment(), 'hours') < 24) {
        messages.push(i18n('additional_cost_will_be_charged'));
      }
      setWarningMessages(messages);
    }
  }, [date, time, route.params, editMode]);

  const getCustomFields = () => {
    return invoiceUserGroup?.customFields || route.params?.customFields || [];
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [failedToFetchLanguageList, setFailedToFetchLanguageList] = useState(false);
  const [isLanguageListFetched, setIsLanguageListFetched] = useState(false);
  const [pagePath, setPagePath] = useState(null);

  const submitting = useRef(Date.now() - 10000);
  const wizardOmitted = useRef(false);

  const [selectableLangs, setSelectableLangs] = useState([]);
  const [stepWizardInstance, setStepWizardInstance] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [skills, setSkills] = useState([]);
  const [statusLookingsForInterpreter, setStatusLookingsForInterpreter] = useState(false);
  const [interpreterPhoneNumber, setInterpreterPhoneNumber] = useState(route.params.phoneNumber || '');

  const [contentData, setContentData] = useState(presence === 'notice' ? parsedNoticeMessage.contentData : '');
  const [customerData, setCustomerData] = useState(presence === 'notice' ? parsedNoticeCustomerData.customerData : '');
  const [customerDataBirthday, setCustomerDataBirthday] = useState(presence === 'notice' && parsedNoticeCustomerData.customerBirthday ? getDateForCustomerBirthday(parsedNoticeCustomerData.customerBirthday) : '');
  const [alternativeContactPerson, setAlternativeContactPerson] = useState(presence === 'notice' ? parsedNoticeMessage.alternativeContactPerson : '');
  const [mainPhoneNumber, setMainPhoneNumber] = useState(presence === 'notice' && parsedNoticeMessage.phoneNumbers ? parsedNoticeMessage.phoneNumbers[0] : '');
  const [phoneNumbers, setPhoneNumbers] = useState(presence === 'notice' && parsedNoticeMessage.phoneNumbers ? parsedNoticeMessage.phoneNumbers?.slice(1)?.join(' ') : '');
  const [messageToCustomerService] = useState(presence === 'notice' ? (route.params.comments || '') : '');

  const [title, setTitle] = useState('');
  const [page, setPage] = useState('');
  const [prevPage, setPrevPage] = useState('');

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [ccEmails, setCcEmails] = useState(getCcEmails(route.params?.ccEmails, EMAILS_AMOUNT));
  const [patientName, setPatientName] = useState(route.params?.patientName || '');
  const [subject, setSubject] = useState(route.params?.subject || '');

  useEffect(() => {
    if (pagePath) {
      setPage(pagePath[pageIndex]);
    }
  }, [pagePath, pageIndex]);

  useEffect(() => {
    if (stepWizardInstance?.state?.activeStep) {
      setPageIndex(stepWizardInstance.state.activeStep);
    }
  }, [stepWizardInstance]);

  const prebooking = pagesArray.includes(stepsNames.dateTime) || presence === 'notice';

  const fetchLanguages = async () => {
    try {
      const response = await fetchLanguageList(true);
      onLanguageListFetched(response);
      setIsLanguageListFetched(true);
    } catch (e) {
      if (e.message === 'Already fetching') {
        setIsLanguageListFetched(true);
        return;
      }

      // const isConnected = navigator.onLine;
      //
      // Sentry.addBreadcrumb({
      //   message: 'Failed to fetch language list',
      //   data: {
      //     'error': e.message,
      //     'HTTP status code': e.response ? String(e.response.status) : '',
      //     'auth token': `${context.user.token?.substring(0, 10)}...`,
      //     'connected': isConnected ? 'yes' : 'no',
      //   }
      // });
      setFailedToFetchLanguageList(true);
    }
  };

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('setSkipConfirmationDialog', {
      detail: {
        skip: true
      }
    }));
  }, []);

  useEffect(() => {
    if (targetLanguages.length === 0 || !(context.user.userGroup && context.user.userGroup.showTargetLanguages)) {
      setSelectedTargetLanguageId(context.user.targetLanguageId || (targetLanguages.length > 0 ? targetLanguages[0].id : null));      
    }
  }, [context.user, targetLanguages, route.params]);

  useEffect(() => {
    setSkills(selectableSkills[selectedLanguageId] || []);
    if ((cloning || editMode) && route.params.skills) {
      route.params.skills.forEach((skill) => {
        const foundSkill = selectableSkills[selectedLanguageId]?.find((aSkill) => (aSkill.id === skill.id));
        if (foundSkill && foundSkill.category.name === stepsNames.dialect) {
          setDialectSkill(foundSkill);
        }
        if (foundSkill && foundSkill.category.name === stepsNames.gender) {
          setGenderSkill(foundSkill);
        }
        if (foundSkill && (foundSkill.category.name !== stepsNames.dialect && foundSkill.category.name !== stepsNames.gender)) {
          setOtherSkill(foundSkill);
        }
      });
    }
  }, [cloning, editMode, route.params, selectedLanguageId, selectableSkills]);

  useEffect(() => {
    const thePath = calculatePath();
    setCurrentPath(thePath);
  }, [pagesArray, wizardOmitted, prebooking, context.user, targetLanguages, skills, selectableSkills, selectedLanguageId, selectedTargetLanguageId, genderSkill, dialectSkill, otherSkill, context.voiceCallSupported, presence, preferredInterpreter, presenceBackup]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectableLanguages(context.user.selectableLanguages || []);
  }, [context.user.selectableLanguages]);
  useEffect(() => {
    setTargetLanguages(context.user.targetLanguages || []);
  }, [context.user.targetLanguages]);
  useEffect(() => {
    setSelectableSkills(context.user.selectableSkills || {});
  }, [context.user.selectableSkills]);
  useEffect(() => {
    setLanguagePairs(context.user.languagePairs || new Map());
  }, [context.user.languagePairs]);
  useEffect(() => {
    setOriginalSelectedLanguageId(context.user.selectedLanguageId);
  }, [context.user.selectedLanguageId]);
  useEffect(() => {
    setSelectedLanguageId(route.params.interpretedLanguage?.id || route.params.selectedLanguageId || context.user.selectedLanguageId);
  }, [route.params.interpretedLanguage?.id, route.params.selectedLanguageId, context.user.selectedLanguageId]);
  useEffect(() => {
    setSelectedTargetLanguageId(context.user.selectedTargetLanguageId);
  }, [context.user.selectedTargetLanguageId]);

  useEffect(() => {
    setPreferredInterpreter(route.params.preferredInterpreter || {});
  }, [route.params.preferredInterpreter]);

  useEffect(() => {
    setSubject(route.params.subject || '');
  }, [route.params.subject]);

  useEffect(() => {
    const minSelectableDate = getMinSelectableDate(presence);

    if (moment(date).isBefore(moment(minSelectableDate), 'day')) {
      setDate(minSelectableDate);
    }
  }, [presence, date]);

  useEffect(() => {
    if (date && time) {
      const startTime = getStartTime(date, time);
      if (startTime.isAfter(time)) {
        setTime(startTime);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function formatAndSetSelactableLanguages (aSelectedLanguageId) {
    if (isLanguageListFetched) {
      const selectableLangsList = formatSelactableLanguages(selectableLanguages, prebooking, presence, targetLanguages, aSelectedLanguageId || selectedTargetLanguageId, languagePairs);

      setSelectableLangs(selectableLangsList);
    }
  }

  useEffect(() => {
    formatAndSetSelactableLanguages();
  }, [presence, selectableLanguages, languagePairs, isLanguageListFetched]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!wizardOmitted.current) {
      setNavigating(false);
      fetchLanguages();
    }
    amplitude.getInstance().logEvent('Chat wizard visible');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const allowedToContinue = {
    [stepsNames.date]: () => Boolean(date),
    [stepsNames.time]: () => Boolean(time),
    [stepsNames.duration]: () => duration > 0,
    [stepsNames.language]: () => selectedLanguageId && selectableLangs.length > 0,
    [stepsNames.targetLanguage]: () => selectedTargetLanguageId && targetLanguages.length > 0,
    [stepsNames.dialect]: () => selectableLanguages.length > 0,
    [stepsNames.gender]: () => selectableLanguages.length > 0,
    [stepsNames.skill]: () => selectableLanguages.length > 0,
    [stepsNames.preferredInterpreter]: () => true,
    [stepsNames.additionalInfo]: () => true,
    [stepsNames.invoiceTargeting]: () => (invoiceUserGroup && invoiceUserGroup.id) || !context.user?.userGroup?.invoiceRefUserGroupMandatory,
    [stepsNames.customerReference]: () => {
      const customFieldsArray = getCustomFields();
      if (customFieldsArray && customFieldsArray.length > 0) {
        return isRequiredCustomFieldsFilled(customFieldsArray, customFieldsValues) && isValidDateInCustomFields(customFieldsArray, customFieldsValues);
      }
      const isInvoiceRefRequired = context?.user?.userGroup?.invoiceRefMandatory || invoiceUserGroup?.invoiceRefMandatory;
      const isNotice = presence === 'notice';
      const isInvoiceRefExist = invoiceRef?.length > 0;
      return (!isInvoiceRefRequired || isNotice || isInvoiceRefExist);
    },
    [stepsNames.callType]: () => Boolean(callType),
    [stepsNames.summary]: () => {
      if (cloning || editMode) {
        if (presence === 'onsite') {
          if (cloning && !customerServiceComments) {
            return false; // do not allow cloning without customerServiceComments
          }
          return locationCity.length > 0 && streetAddress.length > 0 && postcode.length > 0 && messageToInterpreter?.length > 0 && selectedLanguageId && isRequiredCustomFieldsFilled(getCustomFields(), customFieldsValues) && isValidDateInCustomFields(getCustomFields(), customFieldsValues);
        }
        if (presence === 'notice') {
          return customerDataBirthday && new Date(customerDataBirthday) && mainPhoneNumber && contentData;
        }
        return isRequiredCustomFieldsFilled(getCustomFields(), customFieldsValues) && isValidDateInCustomFields(getCustomFields(), customFieldsValues) && invoiceUserGroup?.id;
      }
      return true;
    },
    [stepsNames.presence]: () => presence !== null,
    [stepsNames.location]: () => locationCity.replace(/\s+/g, '')?.length > 1 && streetAddress.replace(/\s+/g, '')?.length > 1 && postcode.replace(/\s+/g, '')?.length > 1 && messageToInterpreter?.replace(/\s+/g, '')?.length > 1,
    [stepsNames.customerInfo]: () => {
      if (editableField === 'customerNameAndDateOfBirth') {
        return (customerData?.length > 0 && customerDataBirthday);
      }
      if (editableField === 'telephoneNumberToWhichTheInterpreterWillCall') {
        return (mainPhoneNumber && isPhoneNumberValid(mainPhoneNumber));
      }
      return (customerData?.length > 0 && customerDataBirthday) && (mainPhoneNumber && isPhoneNumberValid(mainPhoneNumber));
    },
    [stepsNames.content]: () => contentData?.length > 0,
    [stepsNames.ccEmails]: () => {
      const emails = [...ccEmails];
      return emails.reduce((memo, email) => {
        if (memo) {
          return isValidEmail(email);
        }
        return memo;
      }, true);
    },
    [stepsNames.patientName]: () => true,
    [stepsNames.remoteAsBackup]: () => !!presenceBackup,
    [stepsNames.presenceForBackup]: () => !!customerServiceComments
  };

  const isValidEmail = emailToValidate => {
    const email = emailToValidate.replace(/[\s]/g, '');
    return email.length === 0 || (
      email.length >= 6 &&
      email.includes('@') &&
      !email.includes(',') &&
      email.lastIndexOf('.') > email.lastIndexOf('@') &&
      email.lastIndexOf('.') < email.length - 2 &&
      !email.startsWith('@') &&
      email.slice(-1).match(/[a-z0-9]/i));
  };

  const updateCcEmails = (index, email) => {
    const newEmails = [...ccEmails];
    newEmails[index] = email;
    setCcEmails(newEmails);
  };

  const updateBackupData = (aPage) => {
    if (aPage === stepsNames.date) {
      setBackupData({
        ...backupData,
        date,
        time,
      });
    }
    if (aPage === stepsNames.time) {
      setBackupData({
        ...backupData,
        time,
      });
    }
    if (aPage === stepsNames.duration) {
      setBackupData({
        ...backupData,
        duration,
      });
    }
    if (aPage === stepsNames.language) {
      setBackupData({
        ...backupData,
        selectedLanguageId,
        dialectSkill,
        genderSkill,
        otherSkill
      });
    }
    if (aPage === stepsNames.targetLanguage) {
      setBackupData({
        ...backupData,
        selectedTargetLanguageId,
      });
    }
    if (aPage === stepsNames.dialect) {
      setBackupData({
        ...backupData,
        dialectSkill,
      });
    }
    if (aPage === stepsNames.gender) {
      setBackupData({
        ...backupData,
        genderSkill,
      });
    }
    if (aPage === stepsNames.skill) {
      setBackupData({
        ...backupData,
        otherSkill,
      });
    }
    if (aPage === stepsNames.preferredInterpreter) {
      setBackupData({
        ...backupData,
        preferredInterpreter,
      });
    }
    if (aPage === stepsNames.additionalInfo) {
      setBackupData({
        ...backupData,
        ccEmails,
        comment,
        subject,
        patientName
      });
    }
    if (aPage === stepsNames.invoiceTargeting) {
      setBackupData({
        ...backupData,
        invoiceUserGroup: invoiceUserGroup,
      });
    }
    if (aPage === stepsNames.customerReference) {

      setBackupData({
        ...backupData,
        invoiceRef,
        invoiceRecipientRef,
        invoiceUserGroup: invoiceUserGroup,
        customFields: getCustomFields().map((customField) => {
          customField.value = customFieldsValues[customField.name];
          return customField;
        }),
      });
    }
    if (aPage === stepsNames.callType) {
      setBackupData({
        ...backupData,
        callType,
      });
    }
    if (aPage === stepsNames.presence) {
      if (presence === 'onsite') {
        setBackupData({
          ...backupData,
          presence,
          locationCity,
          streetAddress,
          postcode,
          duration,
        });
      } else {
        setBackupData({
          ...backupData,
          presence,
        });
      }
    }
    if (aPage === stepsNames.location) {
      setBackupData({
        ...backupData,
        locationCity,
        streetAddress,
        postcode,
        messageToInterpreter,
      });
    }
    if (aPage === stepsNames.customerInfo) {
      setBackupData({
        ...backupData,
        customerData,
        customerDataBirthday,
        alternativeContactPerson,
        mainPhoneNumber,
        phoneNumbers
      });
    }
    if (aPage === stepsNames.content) {
      setBackupData({
        ...backupData,
        contentData,
      });
    }
    if (aPage === stepsNames.ccEmails) {
      setBackupData({
        ...backupData,
        ccEmails,
      });
    }
    if (aPage === stepsNames.patientName) {
      setBackupData({
        ...backupData,
        patientName,
      });
    }
  };

  useEffect(() => {
    jumpToPage(stepsNames.summary);
  }, [needToGoToSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  const returnDataFromBackupData = (aPage) => {
    if (aPage === stepsNames.date) {
      setDate(backupData.date);
      setTime(backupData.time);
    }
    if (aPage === stepsNames.time) {
      setTime(backupData.time);
    }
    if (aPage === stepsNames.duration) {
      setDuration(backupData.duration);
    }
    if (aPage === stepsNames.language) {
      setSelectedLanguageId(backupData.selectedLanguageId);

      setDialectSkill(backupData.dialectSkill);
      setGenderSkill(backupData.genderSkill);
      setOtherSkill(backupData.otherSkill);
    }
    if (aPage === stepsNames.targetLanguage) {
      setSelectedTargetLanguageId(backupData.selectedTargetLanguageId);
    }
    if (aPage === stepsNames.dialect) {
      setDialectSkill(backupData.dialectSkill);
    }
    if (aPage === stepsNames.gender) {
      setGenderSkill(backupData.genderSkill);
    }
    if (aPage === stepsNames.skill) {
      setOtherSkill(backupData.otherSkill);
    }
    if (aPage === stepsNames.preferredInterpreter) {
      setPreferredInterpreter(backupData.preferredInterpreter);
    }
    if (aPage === stepsNames.additionalInfo) {
      setSubject(backupData.subject);
      setComment(backupData.comment);
      setCcEmails(backupData.ccEmails);
      setPatientName(backupData.patientName);
    }
    if (aPage === stepsNames.invoiceTargeting) {
      setInvoiceUserGroup(backupData.invoiceUserGroup);
    }
    if (aPage === stepsNames.customerReference) {
      setInvoiceRef(backupData?.invoiceRef);
      setInvoiceRecipientRef(backupData?.invoiceRecipientRef);
      setInvoiceUserGroup(backupData?.invoiceUserGroup);
      setCustomFieldsValues(backupData?.customFields ? backupData.customFields.reduce((memo, customField) => {
        return {
          ...memo,
          [customField.name]: customField.value,
        };
      }, {}) : {});
    }
    if (aPage === stepsNames.callType) {
      setCallType(backupData.callType);
    }
    if (aPage === stepsNames.presence) {
      setPresence((backupData.presence === 'external' && backupData.callType === 'call' ? 'remote' : backupData.presence) || 'remote');
      setLocationCity(backupData.locationCity);
      setStreetAddress(backupData.streetAddress);
      setPostcode(backupData.postcode);
      setDuration(backupData.duration);
    }
    if (aPage === stepsNames.location) {
      setLocationCity(backupData.locationCity);
      setStreetAddress(backupData.streetAddress);
      setPostcode(backupData.postcode);
      setMessageToInterpreter(backupData.messageToInterpreter);
    }
    if (aPage === stepsNames.customerInfo) {
      setCustomerData(backupData.customerData);
      setCustomerDataBirthday(backupData.customerDataBirthday);
      setAlternativeContactPerson(backupData.alternativeContactPerson);
      setMainPhoneNumber(backupData.mainPhoneNumber);
      setPhoneNumbers(backupData.phoneNumbers);
    }
    if (aPage === stepsNames.content) {
      setContentData(backupData.contentData);
    }
    if (aPage === stepsNames.ccEmails) {
      setCcEmails(backupData.ccEmails);
    }
    if (aPage === stepsNames.patientName) {
      setPatientName(backupData.patientName);
    }
    setTimeout(() => {
      setNeedToGoToSummary(!needToGoToSummary);
    }, 0);
  };
// eslint-disable-next-line consistent-return
  const onNextPage = () => {
    if (page && allowedToContinue[page]()) {
      document.dispatchEvent(new CustomEvent('setSkipConfirmationDialog', {
        detail: {
          skip: false
        }
      }));
      const fixedPagePath = getFixedPagePath([ ...currentPath ], [stepsNames.ccEmails, stepsNames.patientName]);
      const newPage = pageIndex + 1;
      if (newPage < fixedPagePath.length) {
        amplitude.getInstance().logEvent('Chat wizard - next page', {
          'new page': `${newPage}`,
          'new page name': pagePath[newPage],
          pages: `${pagePath.length}`
        });

        if (stepWizardInstance) {
          stepWizardInstance.nextStep();
        }

        if (editAlternativeCustomerRef) {
          setEditAlternativeCustomerRef('');
        }
        if (editableField) {
          setEditableField('');
        }

        // if (pagePath[newPage] === 'CallType' && !prebooking) {
        //   // TODO: Need to check
        //   // logAnalytics('Ongoing call check');
        //
        //   SafeInteractionManager.runAfterInteractions(() => {
        //     checkOngoingCall();
        //   });
        // }
      } else {
        if (prebooking) {
          if (!route.params.cancelledAt && editMode) {
            return prebook();
          }
          if (
            !route.params.cancelledAt &&
            cloning &&
            presence !== 'notice' &&
            moment(date).isSame(route.params.interpretationDate, 'year') &&
            moment(date).isSame(route.params.interpretationDate, 'month') &&
            moment(date).isSame(route.params.interpretationDate, 'date') &&
            moment(time).isSame(route.params.interpretationDate, 'year') &&
            moment(time).isSame(route.params.interpretationDate, 'month') &&
            moment(time).isSame(route.params.interpretationDate, 'date') &&
            moment(time).isSame(route.params.interpretationDate, 'hour') &&
            moment(time).isSame(route.params.interpretationDate, 'minute')
          ) {
            setShowWarningMessage(true);
            setWarningMessages([i18n('update_at_least_date_or_time_fields_to_create_a_booking')]);
          } else {
            return prebook();
          }
        } else if (route.params?.chatToContinue) {
          continueInterpretation(wizardOmitted.current);
        } else {
          requestInterpretation(wizardOmitted.current);
        }
      }
    } else {
      console.log(`No selection to continue to next page, page: ${page}, call type: ${callType}`);
      amplitude.getInstance().logEvent('No selection to continue to next page', {
        page,
        'is selection': page && allowedToContinue[page]() ? 'yes' : 'no',
        'call type': callType
      });
    }
  };

  const onBack = () => {
    if (context.notificationVisible) {
      return false;
    }
    if (stepWizardInstance.currentStep > 1) {
      amplitude.getInstance().logEvent('Chat wizard - previous page', {
        'new page': `${pageIndex - 1}`,
        'new page name': pagePath[pageIndex - 1],
        pages: `${pagePath.length}`
      });

      if (stepWizardInstance) {
        stepWizardInstance.previousStep();
      }
    } else {
      amplitude.getInstance().logEvent('Chat wizard - back');
      // navigation.pop();
    }
    return true;
  };

  useEffect(() => {
    const aSelectableLangs = getSelectableLangs(context.user, selectableLangs, selectableLanguages);
    if (aSelectableLangs !== null) {
      setSelectableLangs(aSelectableLangs);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const languagesFetched = context.user.selectableLanguages !== undefined;

  const onLanguageListFetched = async response => {
    if (!response || !response.selectableLanguages) {
      // TODO: Need to check
      // Sentry.addBreadcrumb({message: 'No language list response'});
      return;
    }

    // TODO: Need to check
    // Sentry.addBreadcrumb({
    //   message: 'Language list fetched',
    //   data: {
    //     'selectable languages': response.selectableLanguages.length,
    //     'target languages': response.targetLanguages ? response.targetLanguages.length : 'null',
    //     'language pairs': response.languagePairs ? response.languagePairs.length : 'null',
    //     'skills': response.skills ? response.skills.length : 'null',
    //     'selected language id': selectedLanguageId,
    //     'wizard omitted': wizardOmitted.current
    //   }
    // });

    context.user.languagePairs = getLanguagePairs(response.languagePairs);
    context.user.selectableLanguages = getSelectableLanguage(response.selectableLanguages, originalSelectedLanguageId) || [];
    context.user.targetLanguages = response.targetLanguages || [];
    context.user.selectableSkills = response.skills || {};
    context.user.languagesTimestamp = Date.now();

    // TODO: Check if works. The state was updated multiple times and on re-render,
    // some errors appeared cause values are dependent on each other. But anyway we update
    // the user in the context and values by default will be fetched from context.

    // setLanguagePairs(pairs);
    // setSelectableLanguages(response.selectableLanguages);
    // const newTargetLanguages = response.targetLanguages || [];
    // setTargetLanguages(newTargetLanguages);
    // setSelectableSkills(response.skills || {});
    // setFailedToFetchLanguageList(false);

    await updateUser(context.user);
  };

  const jumpToPage = (pageId, anEditableField = '', previousPage) => {
    setPrevPage(previousPage);
    if (stepWizardInstance) {
      // TODO: Need to check
      const index = pagePath.indexOf(pageId);
      stepWizardInstance.goToStep(index + 1);
      if (anEditableField === 'alternativeCostCenter' || anEditableField === 'editCustomGroupFields' || anEditableField === 'customerInvoiceNotes') {
        setEditAlternativeCustomerRef(anEditableField);
      }
      if (presence === 'notice' && anEditableField) {
        setEditableField(anEditableField);
      }
    }
  };

  const stepComponents = useMemo(() => {
    return currentPath.map(p => {
      if (p === stepsNames.date) {
        return {
          page: p,
          date
        };
      } else if (p === stepsNames.time) {
        return {
          page: p,
          time,
          date
        };
      } else if (p === stepsNames.duration) {
        return {
          page: p,
          duration
        };
      } else if (p === stepsNames.presence) {
        return {
          page: p,
          presence
        };
      } else if (p === stepsNames.location) {
        return {
          page: p,
          locationCity,
          streetAddress,
          postcode,
          messageToInterpreter
        };
      } else if (p === stepsNames.language) {
        return {
          page: p,
          selectableLangs,
          languagesFetched,
          selectedLanguageId,
          failedToFetchLanguageList
        };
      } else if (p === stepsNames.targetLanguage) {
        return {
          page: p,
          targetLanguages,
          selectedTargetLanguageId,
          failedToFetchLanguageList
        };
      } else if (p === stepsNames.dialect) {
        return {
          page: p,
          dialectSkill
        };
      } else if (p === stepsNames.gender) {
        return {
          page: p,
          genderSkill
        };
      } else if (p === stepsNames.skill) {
        return {
          page: p,
          otherSkill
        };
      } else if (p === stepsNames.preferredInterpreter) {
        return {
          page: p,
          selectablePreferredInterpreters,
          anyPreference,
          preferredInterpreter,
          failedToFetchPreferredInterpreters
        };
      } else if (p === stepsNames.additionalInfo) {
        return {
          page: p,
          comment,
          subject,
          patientName,
          ccEmails
        };
      } else if (p === stepsNames.invoiceTargeting) {
        return {
          page: p
        };
      } else if (p === stepsNames.customerReference) {
        return {
          page: p
        };
      } else if (p === stepsNames.callType) {
        return {
          page: p,
          callType,
          ongoingCall,
        };
      } else if (p === stepsNames.summary) {
        const targetLanguage = getTargetLanguages(context.user.userGroup, targetLanguages, selectedTargetLanguageId);
        const invoiceUserGroupName = invoiceUserGroup?.id ? invoiceUserGroup?.name : null;
        const invoiceRefMandatory = invoiceUserGroup?.id ? invoiceUserGroup?.invoiceRefMandatory : null;

        return {
          page: p,
          prebookId: route.params.id,
          date,
          time,
          duration,
          languageName: selectableLangs?.find(l => l.id === selectedLanguageId)?.name,
          targetLanguageName: targetLanguage?.name,
          dialectSkillName: dialectSkill?.name,
          genderSkillName: genderSkill?.name,
          otherSkillName: otherSkill?.name,
          callType,
          invoiceUserGroupName,
          invoiceRefMandatory,
          customerServiceComments,
          invoiceRef,
          invoiceRecipientRef,
          messageToInterpreter,
          presence,
          locationCity,
          streetAddress,
          postcode,
          preferredInterpreter,
          comment,
          subject,
          patientName,
          ccEmails
        };
      }

      return {
        page: p
      };
    });
  }, [
    context.user,
    currentPath,
    presence,
    date,
    time,
    duration,
    selectableLangs,
    languagesFetched,
    selectedLanguageId,
    failedToFetchLanguageList,
    targetLanguages,
    selectedTargetLanguageId,
    dialectSkill,
    genderSkill,
    otherSkill,
    anyPreference,
    preferredInterpreter,
    selectablePreferredInterpreters,
    failedToFetchPreferredInterpreters,
    callType,
    customerServiceComments,
    ongoingCall,
    locationCity,
    streetAddress,
    postcode,
    messageToInterpreter,
    comment,
    invoiceRef,
    invoiceRecipientRef,
    invoiceUserGroup,
    route.params.id,
    ccEmails,
    patientName,
    subject
  ]);

  const updateLocalStateForSelectedPresenceType = (value) => {
    const minSelectableDate = getMinSelectableDate(value);
    if (moment(date).isBefore(minSelectableDate, 'year') && moment(date).isBefore(minSelectableDate, 'month') && moment(date).isBefore(minSelectableDate, 'day')) {
      setDate(minSelectableDate);
    }

    if (cloning || editMode) {
      if (value !== 'onsite') {
        setLocationCity('');
        setStreetAddress('');
        setPostcode('');
        setPresenceBackup('');
        setCustomerServiceComments('');
      }
      if (value === 'onsite') {
        setLocationCity(route.params.locationCity || '');
        setStreetAddress(route.params.streetAddress || '');
        setPostcode(route.params.postcode || '');
        updateSomeFieldsInBackupData({
          selectedLanguageId: null
        });
        setSelectedLanguageId(null);
        if (duration < context.user?.userGroup?.minPrebookingDuration || duration < 60) {
          setDuration(60);
        }
      }
    } else {
      if (value === 'onsite') {
        setDate(null);
      }
    }
    const startTime = getStartTime(date, time);
    if (value !== 'onsite' && moment(time).isBefore(startTime, 'hour') && moment(time).isBefore(startTime, 'minute')) {
      setTime(startTime);
    }
    if (value === 'external') {
      setCallType('data');
    }
    if (value === 'notice') {
      setInvoiceRecipientRef('');
      setLocationCity('');
      setStreetAddress('');
      setPostcode('');
      setPresenceBackup('');
      setCustomerServiceComments('');
      const timeForNotice = moment(date).set({
        hour: 18,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      setTime(timeForNotice);
    }
    setPresence(value);
    setCustomerServiceComments('');
  };

  const renderItem = (item, i) => {
    if (item.page === stepsNames.content) {
      return <ContentData
        key={item.page}
        contentData={contentData}
        setContentData={setContentData}
      />;
    } else if (item.page === stepsNames.customerInfo) {
      return <CustomerInfo
        key={item.page}
        editableField={editableField}
        customerData={customerData}
        setCustomerData={setCustomerData}
        customerDataBirthday={customerDataBirthday}
        setCustomerDataBirthday={setCustomerDataBirthday}
        alternativeContactPerson={alternativeContactPerson}
        setAlternativeContactPerson={setAlternativeContactPerson}
        mainPhoneNumber={mainPhoneNumber}
        setMainPhoneNumber={setMainPhoneNumber}
        phoneNumbers={phoneNumbers}
        setPhoneNumbers={setPhoneNumbers}
      />;
    } else if (item.page === stepsNames.date) {
      return <PrebookingDatePage
        key={item.page}
        date={item.date}
        presence={presence}
        onDateChanged={(value) => {
          if (presence === 'notice') {
            const startTime = moment(value).set({
              hour: 18,
              minute: 0,
              second: 0,
              millisecond: 0
            });
            setTime(startTime);
          } else {
            const startTime = getStartTime(value, time);
            if (isFirstTimeBeforeSecond(time, startTime)) {
              setTime(startTime);
            }
          }

          setDate(value);
        }}
        message={presence === 'notice' ? i18n('calendar_tooltip_notice_interpretation') : ''}
      />;
    } else if (item.page === stepsNames.time) {
      return <PrebookingTimePage
        key={item.page}
        time={item.time}
        date={item.date}
        presence={presence}
        onTimeChanged={(value) => {
          setTime(value);
        }}
      />;
    } else if (item.page === stepsNames.duration) {
      return <PrebookingDurationPage
        key={item.page}
        presence={presence}
        duration={item.duration}
        onDurationChanged={setDuration}
      />;
    } else if (item.page === stepsNames.presence) {
      return <PresencePage
        key={item.page}
        cloning={cloning}
        presence={item.presence}
        onChanged={updateLocalStateForSelectedPresenceType}
      />;
    } else if (item.page === stepsNames.location) {
      return <LocationPage
        key={item.page}
        city={item.locationCity}
        onChangedCity={setLocationCity}
        street={item.streetAddress}
        onChangedStreet={setStreetAddress}
        postcode={postcode}
        onChangedPostcode={setPostcode}
        message={item.messageToInterpreter}
        onMessage={setMessageToInterpreter}
        onNext={onNextPage}
        active={pageIndex === i}
      />;
    } else if (item.page === stepsNames.language) {
      const availablePrebookingLangs = item.selectableLangs.filter(l => l.prebooking);
      return <LanguageListPage
        key={item.page}
        title={i18n('select_language_title')}
        selectableLanguages={availablePrebookingLangs}
        languagesFetched={item.languagesFetched}
        selectedLanguageId={item.selectedLanguageId}
        error={item.failedToFetchLanguageList}
        onLanguageSelected={language => {
          amplitude.getInstance().logEvent('Select language', {
            'language id': language?.id,
            'language name': language?.name,
            'language tag': language?.languageTag,
            'language native ename': language?.nativeName,
          });
          if (dialectSkill) {
            const foundDialectSkill = selectableSkills[language?.id]?.find((skill) => {
              return skill.id === dialectSkill?.id;
            });
            if (!foundDialectSkill) {
              setDialectSkill(null);
            }
          }
          if (genderSkill) {
            const foundGenderSkill = selectableSkills[language?.id]?.find((skill) => {
              return skill.id === genderSkill?.id;
            });
            if (!foundGenderSkill) {
              setGenderSkill(null);
            }
          }
          if (otherSkill) {
            const foundOtherSkill = selectableSkills[language?.id]?.find((skill) => {
              return skill.id === otherSkill?.id;
            });
            if (!foundOtherSkill) {
              setOtherSkill(null);
            }
          }
          setSelectedLanguageId(language?.id);
        }}
      />;
    } else if (item.page === stepsNames.targetLanguage) {
      return <LanguageListPage
        key={item.page}
        title={i18n('select_target_language_title')}
        selectableLanguages={item.targetLanguages}
        selectedLanguageId={item.selectedTargetLanguageId}
        error={item.failedToFetchLanguageList}
        onLanguageSelected={language => {
          amplitude.getInstance().logEvent('Select target language', {
            'language id': language.id,
            'language name': language.name,
            'language tag': language.languageTag,
            'language native ename': language.nativeName,
          });
          setSelectedTargetLanguageId(language.id);
          formatAndSetSelactableLanguages(language.id);
        }}
      />;
    } else if (item.page === stepsNames.dialect) {
      return <SkillPage
        key={item.page}
        title={i18n('select_dialect')}
        helpId={'dialect'}
        anyTitle={i18n('any_dialect')}
        selectedSkill={item.dialectSkill}
        onSelected={setDialectSkill}
        selectableSkills={skills.filter(skill => skill.category && skill.category.tag === 'dialect')}
      />;
    } else if (item.page === stepsNames.gender) {
      return <SkillPage
        key={item.page}
        title={i18n('select_gender')}
        helpId={'gender'}
        anyTitle={i18n('any_gender')}
        anyIcon='wc'
        anyIconLib='material'
        selectedSkill={item.genderSkill}
        onSelected={setGenderSkill}
        selectableSkills={skills.filter(skill => skill.category && skill.category.tag === 'gender').map(gender => {
          return {
            ...gender,
            icon: (!gender.tag || gender.tag.includes('_')) ? 'child' : gender.tag,
            iconLib: 'fontAwesome'
          };
        })}
      />;
    } else if (item.page === stepsNames.skill) {
      return <SkillPage
        key={item.page}
        title={i18n('select_skill')}
        anyTitle={i18n('any_skill')}
        selectedSkill={item.otherSkill}
        onSelected={setOtherSkill}
        selectableSkills={skills.filter(skill => !skill.category || (skill.category.tag !== 'dialect' && skill.category.tag !== 'gender' && skill.category.tag !== 'basic'))}
      />;
    } else if (item.page === stepsNames.preferredInterpreter) {
      return <PreferredInterpreterPage 
        key={item.page}
        title={i18n('preferred_interpreters_question')}
        anySelected={item.anyPreference}
        onAnySelected={() => {
          setAnyPreference(true);
          setPreferredInterpreter({});
        }}
        error={item.failedToFetchPreferredInterpreters}
        onPreferredInterpreterSelected={(pi) => setPreferredInterpreter(pi)}
        selectablePreferredInterpreters={selectablePreferredInterpreters}
        preferredInterpreter={item.preferredInterpreter}
      />;
    } else if (item.page === stepsNames.additionalInfo) {
      return <AdditionalPrebookInfo 
        key={item.page}
        cloning={cloning}
        editMode={editMode}
        isNoticeInterpretation={presence === 'notice'}
        comment={item.comment}
        setComment={setComment}
        subject={item.subject}
        setSubject={setSubject}
        ccEmails={item.ccEmails}
        userEmail={context.user.email} 
        onUpdateCcEmails={updateCcEmails}
        patientName={item.patientName}
        setPatientName={setPatientName}
      />;
    } else if (item.page === stepsNames.invoiceTargeting) {
      return <InvoiceTargeting
        key={item.page}
        onValueSelected={(value) => {
          setCustomFieldsValues({});
          setInvoiceUserGroup(value);
        }}
        value={invoiceUserGroup}
      />;
    } else if (item.page === stepsNames.customerReference) {
      return <CustomerReference
        key={item.page}
        active={!editAlternativeCustomerRef && page === 'CustomerReference'}
        editableRefField={editAlternativeCustomerRef}
        invoiceRefMandatory={context.user.userGroup.invoiceRefMandatory || invoiceUserGroup?.invoiceRefMandatory} // TODO
        onValueSelected={setInvoiceRef}
        invoiceRecipientRefValue={invoiceRecipientRef}
        onNext={onNextPage}
        isNoticeInterpretation={presence === 'notice'}
        usergroupCustomFields={getCustomFields()}
        customFieldsValue={customFieldsValues}
        setCustomFieldsValue={(obj) => {
          setCustomFieldsValues({
            ...customFieldsValues,
            ...obj,
          });
        }}
      />;
    } else if (item.page === stepsNames.callType) {
      return <CallTypePage
        key={item.page}
        onChange={setCallType}
        callType={item.callType}
        ongoingCall={item.ongoingCall}
      />;
    } else if (item.page === stepsNames.summary) {
      const foundDialectSkills = selectableSkills[selectedLanguageId]?.find((skill) => skill?.category?.tag === 'dialect');
      const foundGenderSkills = selectableSkills[selectedLanguageId]?.find((skill) => skill?.category?.tag === 'gender');
      const foundOtherSkills = selectableSkills[selectedLanguageId]?.find((skill) => skill?.category?.tag !== 'dialect' && skill?.category?.tag !== 'gender');
      return <PrebookingSummaryPage
        key={item.page}
        date={item.date}
        time={item.time}
        cloning={cloning}
        editMode={editMode}
        showWarningMessage={showWarningMessage}
        duration={item.duration}
        languageName={item.languageName}
        targetLanguageName={item.targetLanguageName}
        dialectSkillName={item.dialectSkillName}
        genderSkillName={item.genderSkillName}
        otherSkillName={item.otherSkillName}
        callType={item.callType}
        invoiceUserGroupName={item.invoiceUserGroupName}
        invoiceRefMandatory={item.invoiceRefMandatory}
        invoiceRef={item.invoiceRef}
        invoiceRecipientRef={item.invoiceRecipientRef}
        comment={item.comment}
        customerServiceComments={customerServiceComments}
        presenceBackup={presenceBackup}
        subject={item.subject}
        ccEmails={item.ccEmails}
        patientName={item.patientName}
        onEditPage={jumpToPage}
        onComment={setComment}
        onMessage={setMessageToInterpreter}
        message={item.messageToInterpreter}
        presence={item.presence}
        locationCity={item.locationCity}
        streetAddress={item.streetAddress}
        postcode={item.postcode}
        orderNumber={item.orderNumber}
        contentData={contentData}
        customerData={customerData}
        customerDataBirthday={customerDataBirthday}
        alternativeContactPerson={alternativeContactPerson}
        mainPhoneNumber={mainPhoneNumber}
        phoneNumbers={phoneNumbers}
        messageToCustomerService={messageToCustomerService}
        needToShowAdditional={currentPath.indexOf('additionalInfo') !== -1}
        needToShowInvoiceTargeting={currentPath.indexOf('InvoiceTargeting') !== -1}
        needToShowCustomerReference={currentPath.indexOf('CustomerReference') !== -1}
        needToShowDialectSkill={presence !== 'notice' && ((cloning || editMode) && !!foundDialectSkills)}
        needToShowGenderSkill={presence !== 'notice' && ((cloning || editMode) && !!foundGenderSkills)}
        needToShowOtherSkill={presence !== 'notice' && ((cloning || editMode) && !!foundOtherSkills)}
        isNoticeInterpretation={presence === 'notice'}
        customGroupFields={getCustomFields()}
        customGroupValues={customFieldsValues}
        warningMessages={warningMessages}
        favoriteNames={item.preferredInterpreter?.name}
      />;
    } else if (item.page === stepsNames.ccEmails) {
      return <CcEmails key={item.page} userEmail={context.user.email} onUpdateCcEmails={updateCcEmails} ccEmails={ccEmails} />;
    } else if (item.page === stepsNames.patientName) {
      return <PatientName key={item.page} patientName={patientName} onUpdatePatientName={setPatientName} />;
    } else if (item.page === stepsNames.remoteAsBackup) {
      return <RemoteAsBackup
        key={item.page}
        onUpdatepresenceBackup={value => {
          const text = value === 'onsite' ? 'CANCEL PREBOOKING' : '';
          setPresenceBackup(value);
          setCustomerServiceComments(text);
        }}
        presenceBackup={presenceBackup}
      />;
    } else if (item.page === stepsNames.presenceForBackup) {
      return <PresenceForBackup
        key={item.page}
        onUpdateCustomerServiceComments={setCustomerServiceComments}
        customerServiceComments={customerServiceComments}
      />;
    }

    return null;
  };

  useEffect(() => {
    const fixedPagePath = getFixedPagePath([ ...currentPath ], [stepsNames.ccEmails, stepsNames.patientName]);
    setTitle(prebooking || pageIndex <= currentPath.length - 1 ?
      i18n('step').replace('{step}', `${pageIndex + 1}`)
      .replace('{steps}', `${fixedPagePath.length}`) : null);
  }, [prebooking, pageIndex, currentPath]);

  const updateLocalStateDependOnPath = (user, pages, builtSteps) => {
    if (builtSteps.includes(stepsNames.language) && !builtSteps.includes(stepsNames.targetLanguage)) {
      if (
        targetLanguages.length > 0 &&
        (!selectedTargetLanguageId || !targetLanguages?.find(l => l.id === selectedTargetLanguageId))
      ) {
        setSelectedTargetLanguageId(targetLanguages[0].id);
      }
    }
    if (!builtSteps.includes(stepsNames.callType) && !cloning) {
      setCallType('data');
    }
  };

  function calculatePath() {
    const stepsArray = buildSteps(pagesArray, context.user, {
      presence,
      targetLanguages,
      skills,
      selectedLanguageId,
      selectedTargetLanguageId,
      otherSkill,
      genderSkill,
      dialectSkill,
      prebooking,
      voiceCallSupported: context.voiceCallSupported,
      callType,
      remoteAsBackup: presenceBackup === 'remote',
    });

    updateLocalStateDependOnPath(context.user, pagesArray, stepsArray);

    if (stepsArray.length === 0 && !wizardOmitted.current) {
      wizardOmitted.current = true;

      if (route.params?.chatToContinue) {
        continueInterpretation(true);
      } else {
        requestInterpretation(true);
      }
    } else {
      setPagePath(stepsArray);
    }

    return stepsArray;
  }

  const getPrebookObject = (isNotice) => {
    const { user } = context;
    date.setHours(new Date(time).getHours(), new Date(time).getMinutes(), 0, 0);
    if (isNotice) {
      return {
        user,
        date,
        duration: contentData?.length <= 500 ? 15 : 30, // Duration depend on content data, if length less than 500 symbols we set duration in 15 minutes, if more than 500 we set 30 minutes
        selectedLanguageId,
        selectedTargetLanguageId,
        callType: 'call',
        invoiceUserGroupId: invoiceUserGroup?.id || '',
        invoiceRef: `${customerData} ${i18n('birthday')} ${moment(customerDataBirthday).format('DD/MM/YYYY')}`,
        invoiceRecipientRef,
        comment: messageToCustomerService,
        presence,
        messageToInterpreter: `${i18n('relay_the_message_to_person')} ${alternativeContactPerson}\n${i18n('numbers_to_be_called')} ${mainPhoneNumber} ${phoneNumbers}\n${i18n('message')}\n ${contentData}`,
        ccEmails: ccEmails.filter(email => email.trim().length > 0).join(', '),
        patientName: patientName
      };
    } else {
      const selectedSkillIds = [];
      if (dialectSkill) {
        selectedSkillIds.push(dialectSkill.id);
      }
      if (genderSkill) {
        selectedSkillIds.push(genderSkill.id);
      }
      if (otherSkill) {
        selectedSkillIds.push(otherSkill.id);
      }

      return {
        user,
        date,
        duration,
        selectedLanguageId,
        selectedTargetLanguageId,
        selectedSkillIds,
        callType,
        invoiceUserGroupId: invoiceUserGroup?.id || '',
        invoiceRef,
        invoiceRecipientRef,
        comment: comment || '',
        favoriteInterpreters: preferredInterpreter?.id ? [preferredInterpreter.id] : [],
        presence: presence === 'remote' && callType === 'call' ? 'external' : presence,
        locationCity,
        streetAddress,
        postcode,
        subject: subject || '',
        messageToInterpreter: messageToInterpreter || '',
        ccEmails: ccEmails.filter(email => email.trim().length > 0).join(', '),
        patientName: patientName,
        customerServiceComments: customerServiceComments || ''
      };
    }
  };

  const createOrUpdatePrebooking = async (isEditPrebook, preparedPrebook, prebookingId) => {
    if (isEditPrebook) {
      return await editPrebooking(preparedPrebook, prebookingId);
    }
    return await createPrebooking(preparedPrebook);
  };

  async function prebook() {
    const prebookId = route.params.id;
    console.log('startted prebook' );
    if (indicator) {
      return;
    }
    setIndicator(true);

    const { user } = context;
    let preparedPrebook = getPrebookObject(presence === 'notice');
    const userGroupCustomFields = getCustomFields();
    const customFieldsForSave = getFormatedCustomFieldsForSave(userGroupCustomFields, customFieldsValues);
    preparedPrebook.customFields = customFieldsForSave;

    try {
      const createdPrebook = await createOrUpdatePrebooking(editMode, preparedPrebook, prebookId);
      const createdPrebookId = createdPrebook.prebookingId || (createdPrebook?.length > 0 ? createdPrebook[0] : null);

      setIndicator(false);

      // const invoiceUserGroupObj = context.user.invoiceRefUserGroups.find(group => group.id === invoiceUserGroup.id);
      const invoiceUserGroupObj = invoiceUserGroup;
      if (prebookId !== createdPrebookId) {
        document.dispatchEvent(new CustomEvent('prebookCreated', {
          detail: {
            callType,
            comments: presence === 'notice' ? messageToCustomerService : (comment || ''),
            durationEstimated: duration * 60 || preparedPrebook.duration * 60,
            id: createdPrebookId,
            interpretationDate: date.getTime(),
            interpretedLanguage: selectableLanguages?.find(lang => lang.id === selectedLanguageId),
            invoiceRef: presence === 'notice' ? `${customerData} ${i18n('birthday')} //` : invoiceRef,
            invoiceRecipientRef,
            invoiceUserGroup: invoiceUserGroupObj,
            messageToInterpreter: presence === 'notice' ? `${i18n('relay_the_message_to_person')} ${alternativeContactPerson}\n${i18n('numbers_to_be_called')} ${mainPhoneNumber} ${phoneNumbers}\n${i18n('message')} ${contentData}` : (messageToInterpreter || ''),
            presence: presence === 'remote' && callType === 'call' ? 'external' : presence,
            // TODO: Need to add skills
            // skills: (2) [{…}, {…}]
            locationCity: locationCity,
            streetAddress: streetAddress,
            postcode: postcode,
            subject: subject, 
            targetLanguage: targetLanguages?.find(l => l.id === selectedTargetLanguageId),
            customer: {
              name: user.name
            },
            cancelForFreeSeconds: 86400,
            customerData: customerData,
            customerDataBirthday: '',
            customerServiceComments: customerServiceComments,
            alternativeContactPerson: alternativeContactPerson,
            mainPhoneNumber: mainPhoneNumber,
            phoneNumbers: phoneNumbers,
            messageToCustomerService: messageToCustomerService,
            contentData: contentData,
            customFields: getCustomFields().map((customField) => ({
              ...customField,
              value: customFieldsValues[customField.name]
            })),
            ccEmails: ccEmails.filter(email => email.trim().length > 0).join(', '),
            patientName
          }
        }));
        if (editMode) {
          document.dispatchEvent(new CustomEvent('prebookCancelled', {
            detail: {
              id: prebookId
            }
          }));
        }
      } else {
        document.dispatchEvent(new CustomEvent('prebookUpdated', {
          detail: {
            prebook: { ...preparedPrebook, id: prebookId }
          }
        }));
      }
      closeModal();
      // navigation.reset({index: 0, routes: [{name: 'Customer'}]});
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      setIndicator(false);
    }
  }

  async function continueInterpretation(reset) {
    if (Date.now() - submitting.current < 5000) {
      amplitude.getInstance().logEvent('Already submitting interpretation request');
      return;
    }

    const chatToContinue = route.params?.chatToContinue;

    console.log(`Continue interpretation: ${chatToContinue}`);
    amplitude.getInstance().logEvent('Continue interpretation with selected call type', {
      'chat room id': chatToContinue.id,
      'call type': callType,
      'reset navigation': reset
    });

    // const otherSkills = chatToContinue.skills?.filter(s => s.category.tag !== 'dialect' && s.category.tag !== 'gender');
    //
    // const params = {
    //   chat: null,
    //   rootChat: route.params.rootChat || null,
    //   continuesChat: chatToContinue,
    //   showLookingForInterpreter: true,
    //   gender: chatToContinue.gender?.name,
    //   dialect: chatToContinue.dialect?.name,
    //   other: otherSkills && otherSkills.length > 0 ? otherSkills[0].name : null,
    //   languageId: chatToContinue.interpretedLanguage?.id,
    //   languageName: chatToContinue.interpretedLanguage?.name,
    //   languageCode: chatToContinue.interpretedLanguage?.languageTag,
    //   selectedSkills: chatToContinue.skills.map(skill => skill.id),
    //   selectedTargetLanguageId: chatToContinue.targetLanguage?.id,
    //   targetLanguageCode: chatToContinue.targetLanguage?.languageTag,
    //   targetLanguageName: chatToContinue.targetLanguage?.name,
    //   invoiceUserGroupId: invoiceUserGroup.id,
    //   invoiceRef,
    //   reason: null,
    //   callType,
    //   returnMethod: reset ? 'reset' : 'pop'
    // };
    //
    // const routeName = callType === 'data' ? 'VideoChat' : 'CallChat';
    //
    // console.log('Navigate: ' + routeName);
    // if (reset) {
    //   navigation.reset({index: 0, routes: [{name: routeName, params}]});
    // } else {
    //   navigation.push(routeName, params);
    // }
  }

  async function requestInterpretation(reset) {
    if (Date.now() - submitting.current < 5000) {
      console.log('Already submitting interpretation request');
      amplitude.getInstance().logEvent('Already submitting interpretation request');
      return;
    }

    console.log('Request interpretation in chat wizard');

    amplitude.getInstance().logEvent('Request interpretation in chat wizard', {
      'call type': callType,
      'reset navigation': reset
    });

    submitting.current = Date.now();

    const { user } = context; // showNotification

    const selectedSkills = [];

      // TODO: check if the call type still supported (shortcuts)

    let languageId;

    if (route.params.customerService || route.params.test) {
      const customerServiceLang =
        selectableLanguages?.find(lang => lang.languageTag === 'testb2b') ||
        selectableLanguages?.find(lang => lang.languageTag === 'ccb2b');

      if (customerServiceLang) {
        languageId = customerServiceLang.id;
      } else {
        console.log('Customer service language not found');
        amplitude.getInstance().logEvent('Customer service language not found');

        onBack();

        // SafeInteractionManager.runAfterInteractions(() => {
        //   showNotification({
        //     message: i18n('failed_to_create_a_chatroom'),
        //     theme: 'error'
        //   });
        // });

        return;
      }
    } else {
      user.callType = callType;
      user.selectedLanguageId = selectedLanguageId;
      user.selectedTargetLanguageId = selectedTargetLanguageId;

      const langIndex = selectableLanguages.findIndex(lang => lang.id === selectedLanguageId);
      if (langIndex > 0) {
        const selectedLanguage = selectableLanguages.splice(langIndex, 1)[0];
        selectableLanguages.sort((l1, l2) => l1.name.localeCompare(l2.name));
        selectableLanguages.unshift(selectedLanguage);
      }

      user.selectableLanguages = selectableLanguages;

      await updateUser(user);

      if (dialectSkill) {
        selectedSkills.push(dialectSkill.id);
      }
      if (genderSkill) {
        selectedSkills.push(genderSkill.id);
      }
      if (otherSkill) {
        selectedSkills.push(otherSkill.id);
      }

      languageId = selectedLanguageId;
    }

    let languageCode = '';
    let languageName = '';
    let targetLanguageCode = '';
    let targetLanguageName = '';
    let nonLanguage = false;

    selectableLanguages?.some(lang => {
      if (lang.id === languageId) {
        languageCode = lang.languageTag;
        languageName = lang.name;
        return true;
      }

      return false;
    });

    targetLanguages?.some(lang => {
      if (lang.id === selectedTargetLanguageId) {
        targetLanguageCode = lang.languageTag;
        targetLanguageName = lang.name;
        nonLanguage = lang.nonLanguage;
        return true;
      }

      return false;
    });

    const chatRoom = route.params.chatRoom ? route.params.chatRoom : await createChatRoom(
      languageId,
      selectedTargetLanguageId,
      selectedSkills,
      callType,
      route.params.prebookingId,
      preferredInterpreter?.id ? preferredInterpreter?.id : null,
      invoiceUserGroup?.id,
      invoiceRef
    );
    // const chatRoom = route.params.chatRoom ? await actions.continueChatRoom(
    //   route.params.chatRoom.id,
    //   route.params.chatRoom.callType
    // ) : await actions.createChatRoom(
    //   languageId,
    //   selectedTargetLanguageId,
    //   selectedSkills,
    //   callType,
    //   route.params.prebookingId,
    //   favoriteInterpreters && favoriteInterpreters.length > 0 ? favoriteInterpreters[0] : null,
    //   invoiceUserGroupId,
    //   invoiceRef
    // );


    const params = {
      // chat: null,
      // rootChat: null,
      // continuesChat: null,
      // showLookingForInterpreter: true,
      apiKey: chatRoom.apiKey,
      chatToken: chatRoom.chatToken,
      sessionId: chatRoom.sessionId,
      roomId: chatRoom.id,
      textChatTokenProviderEndpoint: chatRoom.textChatTokenProviderEndpoint,
      textChatInstanceLocator: chatRoom.textChatInstanceLocator,
      textChatRoomId: chatRoom.textChatRoomId,
      dialect: dialectSkill ? dialectSkill.name : null,
      gender: genderSkill ? genderSkill.name : null,
      other: otherSkill ? otherSkill.name : null,
      nonLanguage,
      languageCode,
      languageName,
      targetLanguageCode,
      targetLanguageName,
      languageId,
      selectedTargetLanguageId,
      selectedSkills,
      callType,
      prebookedInterpreterName: route.params.prebookedInterpreterName,
      prebookingId: route.params.prebookingId,
      invoiceUserGroupId: invoiceUserGroup?.id,
      invoiceRef,
      returnMethod: reset ? 'reset' : 'pop',
      customerService: route.params.customerService,
      preferredInterpreterId: preferredInterpreter?.id ? preferredInterpreter : {},
      test: route.params.test
    };

    if (callType === 'data') {
      context.history.push({
        pathname: '/call',
        state: params
      });
    }
    if (callType === 'call' && !interpreterPhoneNumber) {
      setStatusLookingsForInterpreter(true);
      const intervalId = setInterval(async() => {
        await checkStatus(chatRoom.id, intervalId);

        // if (joinHandledAt.current > 0 && Date.now() - joinHandledAt.current > 10 * 60 * 1000) {
        //   console.log('Max status check time reached');
        //
        //   logAnalytics('Max status check time reached', [
        //     'chat room id', chat.id
        //   ]);
        //   interval();
        // } else if (lastStatusAt.current !== -1) {
        //   lastStatusAt.current = Date.now();
        // }
      }, 5000);
      // setStatusLookingsForInterpreter(true);
    }



    // const routeName = callType === 'data' ? 'VideoChat' : 'CallChat';

    // if (reset) {
    //   navigation.reset({index: 0, routes: [{name: routeName, params}]});
    // } else {
    //   navigation.push(routeName, params);
    // }
  }

  async function checkStatus(chatRoomId, intervalId) {
    try {
      const status = await getChatRoomStatus(chatRoomId);
      // console.log('res ->', status);
      // const status = res.data;

      // if (status.reconnectWebSocket) {
      //   await Native.reconnectWebSocket();
      // }

      if (status.closed) {
        setInterpreterPhoneNumber('');
        setStatusLookingsForInterpreter(false);
        clearInterval(intervalId);
        closeModal();
        amplitude.getInstance().logEvent('Query room status - closed', {
          chatRoomId: chatRoomId
        });
        // showCallEnded('room_info_call_ended');
        return;
      }

      if (status.callAnswered) {
        amplitude.getInstance().logEvent('Query room status - call answered', {
          chatRoomId: chatRoomId
        });

        clearInterval(intervalId);
        setInterpreterPhoneNumber(status.phoneNumber);
        setStatusLookingsForInterpreter(false);
      }

      if (status.joined) {
        clearInterval(intervalId);
        setInterpreterPhoneNumber(status.phoneNumber);
        setStatusLookingsForInterpreter(false);
        amplitude.getInstance().logEvent('Query room status - interpreter joined', {
          chatRoomId: chatRoomId
        });

        // if (context.user.type === 'Customer') {
        //   await interpreterJoined(
        //     chatRoomId,
        //     status.phoneNumber,
        //     status.name,
        //     status.isFavoriteInterpreter,
        //     status.avatarUrl,
        //     status.isCustomerService,
        //     status.isConference,
        //     true);
        // }
      }

      if (status.waiting) {
        amplitude.getInstance().logEvent('Query room status - waiting', {
          chatRoomId: chatRoomId
        });
      }

      // getStatusFailedCount.current = 0;
    } catch (e) {
      console.log('getChatRoomStatus failed', e);

      if (!e.response) {
        amplitude.getInstance().logEvent('Query room status connection failed');
      //   setBadConnectionAt(Date.now());
      //
      //   getStatusFailedCount.current++;
      //   if (getStatusFailedCount.current > 4) {
      //     console.log('Close chatroom - getChatRoomStatus failed several times without response.');
      //
      //     if (context.user.type === 'Customer') {
      //       logAnalytics('Close room - query state connection failed');
      //       showCallEnded('room_info_call_ended');
      //     }
      //   }
      }
    }
  }

  // async function checkOngoingCall() {
  //   console.log('checkOngoingCall');
  //   // TODO: Need to check
  //   // const call = await Native.isCallOnHold();
  //   //
  //   // if (ongoingCall !== call) {
  //   //   logAnalytics('Show conference call suggestion');
  //   //   setOngoingCall(call);
  //   // } else {
  //   //   logAnalytics('No ongoing call');
  //   // }
  // }

  const getInitialStepByCloning = steps => {
    const spepsCloning = getFixedPagePath([ ...steps ], [stepsNames.ccEmails, stepsNames.patientName]);
    return spepsCloning.length;
  };

  const getButtonLabel = (aPage, edit) => {
    if (edit) {
      return i18n('edit_prebooking');
    }
    if (aPage === stepsNames.summary) {
      return i18n('save_prebooking');
    }
    return i18n('next');
  };

  const handleNextButtonClick = async () => {
    const isEditFromSummary = page !== stepsNames.summary && prevPage === stepsNames.summary;
    const isEditUserData = page === stepsNames.ccEmails || page === stepsNames.patientName;
    const isCloneOrEdit = (cloning || editMode) && page !== stepsNames.summary;
    if (isEditFromSummary || isEditUserData || isCloneOrEdit) {
      updateBackupData(page);

      if (page === stepsNames.remoteAsBackup && customerServiceComments !== 'CANCEL PREBOOKING') {
        jumpToPage(stepsNames.presenceForBackup, null, stepsNames.remoteAsBackup);
        return;
      }

      jumpToPage(stepsNames.summary);
    } else {
      onNextPage();
    }
  };

  if (statusLookingsForInterpreter || interpreterPhoneNumber) {
    return <StyledContainer
      colors={colors}
      {...props}
    >
      {interpreterPhoneNumber ? null : (
        <LogoHeader
          title={<CenteredText color={colors.webDarkBlue}>{i18n('searching_interpreter')}</CenteredText>}
        />
      )}
      {interpreterPhoneNumber ? (
        <StyledChatWizardHeader
          color={colors.webDarkBlue}
        >
         {`${i18n('please_call_to_this_number')} ${interpreterPhoneNumber}`}
        </StyledChatWizardHeader>
      ) : <Spinner/>}
    </StyledContainer>;
  }

  if (wizardOmitted.current) {
    return (
      <StyledContainer
        colors={colors}
        {...props}
      >
        <LogoHeader />
        <Spinner/>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer
      colors={colors}
      {...props}
    >
      {isLanguageListFetched ? (
        <>
        {
          !(page === stepsNames.ccEmails || page === stepsNames.patientName) && (
            <LogoHeader
              title={title}
            />
          )
        }
        <StyledStepWizard
          instance={setStepWizardInstance}
          initialStep={cloning || editMode ? getInitialStepByCloning(stepComponents) : 1}
          onStepChange={(stepData) => {
            setPageIndex(stepData.activeStep - 1);
          }}
          isLazyMount={true}
        >
          {stepComponents.map((data, i) => renderItem(data, i))}
        </StyledStepWizard>
        <ButtonPanel>
          {(((cloning || editMode ) && page === stepsNames.summary)) ? null : (
            <RoundButton
              data-test='BackBtnChatWizard'
              label={i18n('back')}
              // eslint-disable-next-line
              onClick={() => {
                if (page === stepsNames.ccEmails || page === stepsNames.patientName) {
                  returnDataFromBackupData(page);
                  return jumpToPage(stepsNames.summary);
                }
                if (cloning || editMode) {
                  returnDataFromBackupData(page);
                } else {
                  onBack();
                }
              }}
              primary
              disabled={cloning || editMode ? false : pageIndex === 0}
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
              indicator={indicator}
            />
          )}
          <RoundButton
            data-test={getButtonLabel(page, editMode) + 'BtnChatWizard'}
            label={getButtonLabel(page, editMode)}
            // eslint-disable-next-line
            onClick={handleNextButtonClick}
            primary
            darkButton
            disabled={page !== '' && allowedToContinue[page] && !allowedToContinue[page]()}
            color={colors.tulkaMainColor}
            padding={layout.buttonPadding}
            indicator={indicator}
          />
        </ButtonPanel>
          {indicator ? (
            <Spinner
              overlay
            />
          ) : null}
        </>
      ) : (
        <Spinner/>
      )}
    </StyledContainer>
  );
};

export default ChatWizard;
